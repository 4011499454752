import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DialogWrapper from "../../helpers/DialogWrapper";
import * as Actions from "../../store/actions";
import MaterialContent from "./MaterialContent";
import SurveryDialogContent from "./SurveryDialogContent";
import SurveyTable from "./SurveyTable";


const SurveyAndMaterialScreen = props => {

    const dispatch = useDispatch();

    const [SurveyDialogOpen, setSurveyDialogOpen] = useState(false);
    const [IsSurveyLoading, setIsSurveyLoading] = useState(true);
    const [SelectedSurvey, setSelectedSurvey] = useState(null);
    const [CurrentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [AllQuestions, setAllQuestions] = useState([]);
    const [TotalQuestions, setTotalQuestions] = useState(0);
    const [ErrorMsg, setErrorMsg] = useState("");


    useEffect(() => {
        if (!SurveyDialogOpen) {
            setIsSurveyLoading(true);
            setSelectedSurvey(null);
            setCurrentQuestionIndex(0);
            setAllQuestions([]);
            setTotalQuestions(0);
            setErrorMsg("");
        }
    }, [SurveyDialogOpen]);



    async function GetSurveyQuestions(id, isSpanish = false) {
        try {
            const res = await dispatch(Actions.GetSurveyQuestions(id, isSpanish));

            if (!res) throw "Survey failed to load";

            setTotalQuestions(res.length);
            setAllQuestions(res);
            setIsSurveyLoading(false);

        } catch (error) {
            setIsSurveyLoading(false);
            setErrorMsg("Survey failed to load");
        }
    }


    async function moveToNextQuestion(answer) {
        try {
            setCurrentQuestionIndex(CurrentQuestionIndex + 1);
        } catch (error) {
            console.log(error);
        }
    }
    async function moveToPreviousQuestion(answer) {
        try {
            setCurrentQuestionIndex(CurrentQuestionIndex - 1);
        } catch (error) {
            console.log(error);
        }
    }


    async function submitSurvey(Answers) {
        setCurrentQuestionIndex(CurrentQuestionIndex + 1);
        await dispatch(Actions.SubmitSurvey(Answers, SelectedSurvey, AllQuestions));
        props.getSurvey();
    }

    return (
        <div className="surveyMaterial pt-1 pb-5" >
            <div className="container-fluid" >
                <h5 className="py-3" >
                    Survey & Education Material
                </h5>

                <div className="card border-0 pb-3" >
                    <div className="py-2 px-3"
                        style={{ color: "#333333", fontSize: "18px", fontWeight: "500", borderBottom: "1px solid #e4ebf3" }}
                    >
                        Today's Survey
                    </div>
                    <div className="card-body py-2" >

                        {
                            props.IsLoadingSurvey ? (
                                <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </div>
                            ) : null
                        }
                        {
                            !props.IsLoadingSurvey && props.SurveyList.length === 0 ? (
                                <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <h3 style={{ color: "grey" }}>No survey found</h3>
                                </div>
                            ) : null
                        }
                        {
                            !props.IsLoadingSurvey && props.SurveyList.length > 0 ? (
                                <SurveyTable

                                    data={props.SurveyList}
                                    onStart={(value, isSpanish) => {
                                        // setStep( )
                                        setSurveyDialogOpen(true);
                                        setSelectedSurvey(value);
                                        GetSurveyQuestions(value.tblPKId, isSpanish);
                                    }}
                                />
                            ) : null
                        }

                    </div>
                </div>

                <MaterialContent
                    IsLoadingMaterial={props.IsLoadingMaterial}
                    SurveyAndMaterialData={props.SurveyAndMaterialData}
                />
            </div>

            {
                SurveyDialogOpen ? (
                    <DialogWrapper
                        NoFooter
                        open={SurveyDialogOpen}
                        title={
                            <>
                                <h4 className="mb-1" style={{ color: "#333333" }} >
                                    {SelectedSurvey?.name}
                                </h4>
                                {
                                    CurrentQuestionIndex >= TotalQuestions ? null : (
                                        <p className="mb-1" style={{ fontWeight: "500", color: "#7b7b7b", fontSize: "15px" }} >
                                            Please complete the following questionnaire.
                                        </p>
                                    )
                                }

                            </>
                        }
                        titleColor="#333"
                        onClose={() => setSurveyDialogOpen(false)}
                        content={

                            <SurveryDialogContent
                                IsSurveyLoading={IsSurveyLoading}
                                SelectedSurvey={SelectedSurvey}
                                CurrentQuestionIndex={CurrentQuestionIndex}
                                TotalQuestions={TotalQuestions}
                                ErrorMsg={ErrorMsg}
                                CurrentQuestion={AllQuestions[CurrentQuestionIndex]}
                                Finished={CurrentQuestionIndex >= TotalQuestions}
                                Last={CurrentQuestionIndex + 1 == TotalQuestions}
                                moveToNextQuestion={(answers) => moveToNextQuestion(answers)}
                                submitSurvey={(answers) => submitSurvey(answers)}
                                moveToPreviousQuestion={(answers) => moveToPreviousQuestion(answers)}
                            />

                        }
                    />
                ) : null
            }
        </div>
    );
};

SurveyAndMaterialScreen.propTypes = {
    fromPatientSide: PropTypes.bool.isRequired,
    IsLoading: PropTypes.bool.isRequired,
    SurveyAndMaterialData: PropTypes.object.isRequired,
    ErrorMsg: PropTypes.string.isRequired,
};

export default SurveyAndMaterialScreen;
