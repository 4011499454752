import * as ReducerActions from "../reducers";
import CryptoJS from "crypto-js";
import { getBaseURL } from "../../helpers/getBaseURL";
import axios from "axios";


const urlParams = new URLSearchParams(window.location.search);

const doctorAdminId = urlParams.get("doctorAdminId");
const privateKeyForDoctor = urlParams.get("privateKey");
const isIframe = urlParams.get("isIframe");
const clinicGroupItemId = urlParams.get("clinicGroupItemId");
const ProviderToken = urlParams.get("token");

sessionStorage.setItem("@doctorAdminId", doctorAdminId);
sessionStorage.setItem("@privateKeyForDoctor", privateKeyForDoctor);

export const openCloseSideBar = () => async (dispatch, getState) => {
    try {

        dispatch(ReducerActions.openCloseSideBar());


    } catch (error) {
        console.log(error);
    }
};

function arc4random() {
    return Math.floor(Math.random() * 4294967296);
}

function padString(source) {
    let size = 16;
    let x = source.length % size;
    let padLength = size - x;
    let paddingChar = String.fromCharCode(128 + padLength); //+128 to avoid ASCII 7 bit char

    for (let i = 0; i < 128 + padLength; i++) source += paddingChar; //" "

    return source;
}

export const getQueryParamValue = (key) => {
    return urlParams.get(key);
};

export const getUserID = (getState) => {


    if (isIframe) return doctorAdminId;

    return getState().User.UserData?.userId || 0;
};

export const getClinicGroupItemId = () => {
    // return 1273;
    if (isIframe) return clinicGroupItemId;
    return 0;
};

export const getToken = (getState) => {

    let primaryKey = 0;
    let privateKey = 0;
    if (isIframe) {
        primaryKey = doctorAdminId || 0;
        privateKey = privateKeyForDoctor || 0;
    } else {
        primaryKey = getState().User.UserData?.userId || 0;
        privateKey = getState().User.UserData?.privateKey || 0;
    }


    const FIX_TOKEN = "HhcNMTQwMTMwMjMzNjUwWhcNMTUwMTMwMjMzNjUwWjCBjjElMCMGCgmSJomT8ixk";
    const preV = "jVgklxOHpE2IXNbnWDwb";
    const saltStr = "8B7072263C8A4b1dA1831BE47EB19366";
    const ivStr = "B5B399AA62949089BEEF86637B81773E";


    var nowdate = new Date();

    var r = arc4random() % 1000;

    var docOrUserId = primaryKey;
    var token = FIX_TOKEN + "_" + primaryKey;


    if (!docOrUserId) {
        return token;
    }
    if (!privateKey) {
        return token;
    }

    if (privateKey === FIX_TOKEN) {
        return token;
    }

    var t = parseInt(nowdate.getTime() / 1000, 10) % 1000;

    token = r + "_" + preV + "_" + primaryKey + "_" + t;

    token = token.trim();

    var iv = CryptoJS.enc.Hex.parse(ivStr);

    var salt = CryptoJS.enc.Hex.parse(saltStr);

    var key = CryptoJS.PBKDF2(privateKey, salt, { hasher: CryptoJS.algo.SHA256, keySize: 64 / 8, iterations: 999 });

    var encrypted = CryptoJS.AES.encrypt(token, key, { iv: iv });

    var newV = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    newV = newV.replace(/\+/g, "-");
    newV = newV.replace(/\//g, "_");
    newV = newV.replace(/=/g, "~");

    return newV;

};


export const getParamsForPatient = (getState) => {
    return `?patientIdParam=${getUserID(getState)}&passcode=health001~AABO&token=${getToken(getState)}`;
};

export const getParamsForDoctor = (getState) => {

    return `?doctorIdParam=${getUserID(getState)}&passcode=health001~AABO&token=${getToken(getState)}`;
};

export const getParams = (getState) => {

    if (isIframe) return getParamsForDoctor();

    return getParamsForPatient(getState);

};

export const getParamsWithExtraItems = (getState) => {

    if (isIframe) return getParamsForDoctor();

    return getParamsForPatient(getState);

};


export const CheckValidAccessCodeToken = (token, navigate) => async (dispatch, getState) => {

    const res = await axios.get(`${getBaseURL()}/checkValidAccessCodeToken/t/${token}`);

    if (res.data.surveyType) {
        if (res.data.surveyType == "clinicsurvey") navigate("/survey-page?accessCode=" + token);
        if (res.data.surveyType == "educationalsurvey") navigate("/material-page?accessCode=" + token);
        if (res.data.surveyType == "treatmentsurvey") navigate("/hep-page?accessCode=" + token);
        if (res.data.surveyType == "compliancesurvey") navigate("/compliance-page?accessCode=" + token);
    }

    dispatch(ReducerActions.setHEPData(res.data || null));
    return res;
};