

export const LIGHT_GREEN = "rgb(6, 149, 219)";
export const ACTUAL_GREEN = "#13bb00";


export const DARK_BLUE = "rgb(37, 112, 183)";
export const LIGHT_BLUE = "rgb(6, 149, 219)";
export const GOLD = "rgb(255,255,255)";

export const SIDE_BAR_HOVER = "rgb(2, 116, 172)";

