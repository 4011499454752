import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";


const urlParams = new URLSearchParams(window.location.search);
const syplogsummaryid = urlParams.get("sypLogSummaryId");
const userId = urlParams.get("userId");

export const getIntakeSurvey = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/getSurveyReportDetails${getParams()}&REPORT_COUNT=4&SURVEY_TYPE=SURVEY&otherLanguage=0&sypLogTypeId=266&userId=${userId}&syplogsummaryid=${syplogsummaryid}`);

        if (!res.data.QUESANSWERS) return null;
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


export const GetAllSurveyAndMaterial = () => async (dispatch, getState) => {
    try {

        const res = await axios.get(`${getBaseURL()}/getTodaysPrescribedMaterials${getParams(getState)}&userId=${getUserID(getState)}`,);

        dispatch(ReducerActions.setSurveyAndMaterialData(res.data.reverse()));


    } catch (error) {
        console.log(error);
    }
};


export const GetAllSurveys = () => async (dispatch, getState) => {
    try {

        let today = new Date();
        let priorDate = new Date().setDate(today.getDate() - 30);
        const res = await axios.get(`${getBaseURL()}/getPrescribedSurvey${getParams(getState)}&userId=${getUserID(getState)}&versionId=${new Date(priorDate).toISOString()}`,);


        dispatch(ReducerActions.setSurveyList(res.data.data));


    } catch (error) {
        console.log(error);
        console.log(error);
    }
};



export const GetSurveyQuestions = (sypID, isSpanish, taskScheduleId = 0) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/getSurveyQuestionsForPatientPortal${getParams(getState)}&syplogTypeId=${sypID}&userId=${userId || getUserID(getState)}&taskScheduleId=${taskScheduleId}`,);

        let data = res.data;

        if (isSpanish) {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];

                element.Question.questionDetail = element.ForeignQuestion?.textdata || element.Question.questionDetail;

                let answers = element.AnswerChoice;
                for (let index2 = 0; index2 < element.AnswerChoice.length; index2++) {
                    let element2;
                    if (element?.ForeignChoice) {
                        element2 = element?.ForeignChoice[index2];
                    } else {
                        element2 = element.AnswerChoice[index2];
                    }


                    answers[index2].description = element2?.textdata || answers[index2].description;
                }
                element.AnswerChoice = answers;

                data[index] = element;
            }

        }
        return data;

    } catch (error) {
        console.log(error);
    }
};


export const SavePatientMaterialLogSummary = (sypLogTypeId, exerciseVideoId, logTime, logEndTime) => async (dispatch, getState) => {
    try {


        const body = {
            "userId": getUserID(getState),
            "sypLogTypeId": sypLogTypeId,
            "exerciseVideoId": exerciseVideoId,
            "logTime": logTime,
            "logEndTime": logEndTime
        };

        const res = await axios.post(`${getBaseURL()}/PatientMaterialLogSummary/${getUserID(getState)}/${getUserID(getState)}/${getUserID(getState)}/${getUserID(getState)}${getParams(getState)}`, body);


    } catch (error) {
        console.log(error);
    }
};


export const SubmitSurvey = (Answers, CurrentSurvey, Questions, surveyType, tid) => async (dispatch, getState) => {

    try {

        const body = {
            sypLogData: Answers,
            "userSypLogRelId": Questions[0].userSypLogRelId,
            "userId": getUserID(getState),
            "adminDocterId": CurrentSurvey.adminDocterId || "0",
            "bookScheduleId": CurrentSurvey.taskScheduleId || "0",
            "syplogTypeId": Questions[0].Question.sypLogTypeId,
            "surveyType": surveyType ? surveyType : "clinicsurvey",
            "bookScheduleSypLogRelId": "" || "0",
            "treatmentId": tid || ""
        };

        const res = await axios.post(`${getBaseURL()}/submitSurveyData${getParams(getState)}`, body);

        dispatch(ReducerActions.setSypLogSummaryId(res.data.sypLogSummaryId));
        // toast.success("Survey ")
        return true

    } catch (error) {
        console.log(error);
    }
};

export const GetMaterialsBySyplogTypeId = (res) => async (dispatch, getState) => {

    try {

        const videoData = await axios.get(`${getBaseURL()}/getMaterialsBySyplogTypeId${getParams(getState)}&syplogTypeId=${res.data.surveyTypeId}`);

        return videoData;

    } catch (error) {
        console.log(error);
    }
};


export const GetSurveyQuestionsBysyplogTypeId = (res) => async (dispatch, getState) => {

    try {

        const surveyQuestions = await axios.get(`${getBaseURL()}/getSurveyQuestions${getParams(getState)}&bookScheduleId=${res?.data?.TaskSchedule?.taskScheduleId}&syplogTypeId=${res?.data?.TaskSchedule?.tblPKId}&adminDocterId=${res.data.DoctorPatientInfo.doctorInfo.adminDocterId}&userId=${getUserID(getState)}`);

        return surveyQuestions;

    } catch (error) {
        console.log(error);
    }
};


