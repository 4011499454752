export const CountryData = [
    {
        "CountryId": "5",
        "Country": "Andorra",
        "FIPS104": "AN",
        "ISO2": "AD",
        "countryPhoneCode": "376"
    },
    {
        "CountryId": "252",
        "Country": "United Arab Emirates",
        "FIPS104": "AE",
        "ISO2": "AE",
        "countryPhoneCode": "971"
    },
    {
        "CountryId": "1",
        "Country": "Afghanistan",
        "FIPS104": "AF",
        "ISO2": "AF",
        "countryPhoneCode": "93"
    },
    {
        "CountryId": "9",
        "Country": "Antigua and Barbuda",
        "FIPS104": "AC",
        "ISO2": "AG",
        "countryPhoneCode": "1268"
    },
    {
        "CountryId": "7",
        "Country": "Anguilla",
        "FIPS104": "AV",
        "ISO2": "AI",
        "countryPhoneCode": "1264"
    },
    {
        "CountryId": "2",
        "Country": "Albania",
        "FIPS104": "AL",
        "ISO2": "AL",
        "countryPhoneCode": "355"
    },
    {
        "CountryId": "11",
        "Country": "Armenia",
        "FIPS104": "AM",
        "ISO2": "AM",
        "countryPhoneCode": "374"
    },
    {
        "CountryId": "176",
        "Country": "Netherlands Antilles",
        "FIPS104": "NT",
        "ISO2": "AN",
        "countryPhoneCode": "599"
    },
    {
        "CountryId": "6",
        "Country": "Angola",
        "FIPS104": "AO",
        "ISO2": "AO",
        "countryPhoneCode": "244"
    },
    {
        "CountryId": "8",
        "Country": "Antarctica",
        "FIPS104": "AY",
        "ISO2": "AQ",
        "countryPhoneCode": "0"
    },
    {
        "CountryId": "10",
        "Country": "Argentina",
        "FIPS104": "AR",
        "ISO2": "AR",
        "countryPhoneCode": "54"
    },
    {
        "CountryId": "4",
        "Country": "American Samoa",
        "FIPS104": "AQ",
        "ISO2": "AS",
        "countryPhoneCode": "1684"
    },
    {
        "CountryId": "15",
        "Country": "Austria",
        "FIPS104": "AU",
        "ISO2": "AT",
        "countryPhoneCode": "43"
    },
    {
        "CountryId": "14",
        "Country": "Australia",
        "FIPS104": "AS",
        "ISO2": "AU",
        "countryPhoneCode": "61"
    },
    {
        "CountryId": "12",
        "Country": "Aruba",
        "FIPS104": "AA",
        "ISO2": "AW",
        "countryPhoneCode": "297"
    },
    {
        "CountryId": "16",
        "Country": "Azerbaijan",
        "FIPS104": "AJ",
        "ISO2": "AZ",
        "countryPhoneCode": "994"
    },
    {
        "CountryId": "30",
        "Country": "Bosnia and Herzegovina",
        "FIPS104": "BK",
        "ISO2": "BA",
        "countryPhoneCode": "387"
    },
    {
        "CountryId": "21",
        "Country": "Barbados",
        "FIPS104": "BB",
        "ISO2": "BB",
        "countryPhoneCode": "1246"
    },
    {
        "CountryId": "20",
        "Country": "Bangladesh",
        "FIPS104": "BG",
        "ISO2": "BD",
        "countryPhoneCode": "880"
    },
    {
        "CountryId": "24",
        "Country": "Belgium",
        "FIPS104": "BE",
        "ISO2": "BE",
        "countryPhoneCode": "32"
    },
    {
        "CountryId": "38",
        "Country": "Burkina Faso",
        "FIPS104": "UV",
        "ISO2": "BF",
        "countryPhoneCode": "226"
    },
    {
        "CountryId": "37",
        "Country": "Bulgaria",
        "FIPS104": "BU",
        "ISO2": "BG",
        "countryPhoneCode": "359"
    },
    {
        "CountryId": "18",
        "Country": "Bahrain",
        "FIPS104": "BA",
        "ISO2": "BH",
        "countryPhoneCode": "973"
    },
    {
        "CountryId": "40",
        "Country": "Burundi",
        "FIPS104": "BY",
        "ISO2": "BI",
        "countryPhoneCode": "257"
    },
    {
        "CountryId": "26",
        "Country": "Benin",
        "FIPS104": "BN",
        "ISO2": "BJ",
        "countryPhoneCode": "229"
    },
    {
        "CountryId": "27",
        "Country": "Bermuda",
        "FIPS104": "BD",
        "ISO2": "BM",
        "countryPhoneCode": "1441"
    },
    {
        "CountryId": "36",
        "Country": "Brunei Darussalam",
        "FIPS104": "BX",
        "ISO2": "BN",
        "countryPhoneCode": "673"
    },
    {
        "CountryId": "29",
        "Country": "Bolivia",
        "FIPS104": "BL",
        "ISO2": "BO",
        "countryPhoneCode": "591"
    },
    {
        "CountryId": "33",
        "Country": "Brazil",
        "FIPS104": "BR",
        "ISO2": "BR",
        "countryPhoneCode": "55"
    },
    {
        "CountryId": "17",
        "Country": "The Bahamas",
        "FIPS104": "BF",
        "ISO2": "BS",
        "countryPhoneCode": "1242"
    },
    {
        "CountryId": "28",
        "Country": "Bhutan",
        "FIPS104": "BT",
        "ISO2": "BT",
        "countryPhoneCode": "975"
    },
    {
        "CountryId": "32",
        "Country": "Bouvet Island",
        "FIPS104": "BV",
        "ISO2": "BV",
        "countryPhoneCode": "0"
    },
    {
        "CountryId": "31",
        "Country": "Botswana",
        "FIPS104": "BC",
        "ISO2": "BW",
        "countryPhoneCode": "267"
    },
    {
        "CountryId": "23",
        "Country": "Belarus",
        "FIPS104": "BO",
        "ISO2": "BY",
        "countryPhoneCode": "375"
    },
    {
        "CountryId": "25",
        "Country": "Belize",
        "FIPS104": "BH",
        "ISO2": "BZ",
        "countryPhoneCode": "501"
    },
    {
        "CountryId": "43",
        "Country": "Canada",
        "FIPS104": "CA",
        "ISO2": "CA",
        "countryPhoneCode": "1"
    },
    {
        "CountryId": "52",
        "Country": "Cocos (Keeling) Islands",
        "FIPS104": "CK",
        "ISO2": "CC",
        "countryPhoneCode": "672"
    },
    {
        "CountryId": "46",
        "Country": "Central African Republic",
        "FIPS104": "CT",
        "ISO2": "CF",
        "countryPhoneCode": "236"
    },
    {
        "CountryId": "56",
        "Country": "Congo, Republic of the",
        "FIPS104": "CF",
        "ISO2": "CG",
        "countryPhoneCode": "242"
    },
    {
        "CountryId": "234",
        "Country": "Switzerland",
        "FIPS104": "SZ",
        "ISO2": "CH",
        "countryPhoneCode": "41"
    },
    {
        "CountryId": "60",
        "Country": "Cote d'Ivoire",
        "FIPS104": "IV",
        "ISO2": "CI",
        "countryPhoneCode": "225"
    },
    {
        "CountryId": "57",
        "Country": "Cook Islands",
        "FIPS104": "CW",
        "ISO2": "CK",
        "countryPhoneCode": "682"
    },
    {
        "CountryId": "48",
        "Country": "Chile",
        "FIPS104": "CI",
        "ISO2": "CL",
        "countryPhoneCode": "56"
    },
    {
        "CountryId": "42",
        "Country": "Cameroon",
        "FIPS104": "CM",
        "ISO2": "CM",
        "countryPhoneCode": "237"
    },
    {
        "CountryId": "49",
        "Country": "China",
        "FIPS104": "CH",
        "ISO2": "CN",
        "countryPhoneCode": "86"
    },
    {
        "CountryId": "53",
        "Country": "Colombia",
        "FIPS104": "CO",
        "ISO2": "CO",
        "countryPhoneCode": "57"
    },
    {
        "CountryId": "59",
        "Country": "Costa Rica",
        "FIPS104": "CS",
        "ISO2": "CR",
        "countryPhoneCode": "506"
    },
    {
        "CountryId": "62",
        "Country": "Cuba",
        "FIPS104": "CU",
        "ISO2": "CU",
        "countryPhoneCode": "53"
    },
    {
        "CountryId": "44",
        "Country": "Cape Verde",
        "FIPS104": "CV",
        "ISO2": "CV",
        "countryPhoneCode": "238"
    },
    {
        "CountryId": "50",
        "Country": "Christmas Island",
        "FIPS104": "KT",
        "ISO2": "CX",
        "countryPhoneCode": "61"
    },
    {
        "CountryId": "63",
        "Country": "Cyprus",
        "FIPS104": "CY",
        "ISO2": "CY",
        "countryPhoneCode": "357"
    },
    {
        "CountryId": "64",
        "Country": "Czech Republic",
        "FIPS104": "EZ",
        "ISO2": "CZ",
        "countryPhoneCode": "420"
    },
    {
        "CountryId": "91",
        "Country": "Germany",
        "FIPS104": "GM",
        "ISO2": "DE",
        "countryPhoneCode": "49"
    },
    {
        "CountryId": "66",
        "Country": "Djibouti",
        "FIPS104": "DJ",
        "ISO2": "DJ",
        "countryPhoneCode": "253"
    },
    {
        "CountryId": "65",
        "Country": "Denmark",
        "FIPS104": "DA",
        "ISO2": "DK",
        "countryPhoneCode": "45"
    },
    {
        "CountryId": "67",
        "Country": "Dominica",
        "FIPS104": "DO",
        "ISO2": "DM",
        "countryPhoneCode": "1767"
    },
    {
        "CountryId": "68",
        "Country": "Dominican Republic",
        "FIPS104": "DR",
        "ISO2": "DO",
        "countryPhoneCode": "1809"
    },
    {
        "CountryId": "3",
        "Country": "Algeria",
        "FIPS104": "AG",
        "ISO2": "DZ",
        "countryPhoneCode": "213"
    },
    {
        "CountryId": "70",
        "Country": "Ecuador",
        "FIPS104": "EC",
        "ISO2": "EC",
        "countryPhoneCode": "593"
    },
    {
        "CountryId": "75",
        "Country": "Estonia",
        "FIPS104": "EN",
        "ISO2": "EE",
        "countryPhoneCode": "372"
    },
    {
        "CountryId": "71",
        "Country": "Egypt",
        "FIPS104": "EG",
        "ISO2": "EG",
        "countryPhoneCode": "20"
    },
    {
        "CountryId": "267",
        "Country": "Western Sahara",
        "FIPS104": "WI",
        "ISO2": "EH",
        "countryPhoneCode": "212"
    },
    {
        "CountryId": "74",
        "Country": "Eritrea",
        "FIPS104": "ER",
        "ISO2": "ER",
        "countryPhoneCode": "291"
    },
    {
        "CountryId": "226",
        "Country": "Spain",
        "FIPS104": "SP",
        "ISO2": "ES",
        "countryPhoneCode": "34"
    },
    {
        "CountryId": "76",
        "Country": "Ethiopia",
        "FIPS104": "ET",
        "ISO2": "ET",
        "countryPhoneCode": "251"
    },
    {
        "CountryId": "81",
        "Country": "Finland",
        "FIPS104": "FI",
        "ISO2": "FI",
        "countryPhoneCode": "358"
    },
    {
        "CountryId": "80",
        "Country": "Fiji",
        "FIPS104": "FJ",
        "ISO2": "FJ",
        "countryPhoneCode": "679"
    },
    {
        "CountryId": "160",
        "Country": "Micronesia, Federated States of",
        "FIPS104": "FM",
        "ISO2": "FM",
        "countryPhoneCode": "691"
    },
    {
        "CountryId": "79",
        "Country": "Faroe Islands",
        "FIPS104": "FO",
        "ISO2": "FO",
        "countryPhoneCode": "298"
    },
    {
        "CountryId": "82",
        "Country": "France",
        "FIPS104": "FR",
        "ISO2": "FR",
        "countryPhoneCode": "33"
    },
    {
        "CountryId": "87",
        "Country": "Gabon",
        "FIPS104": "GB",
        "ISO2": "GA",
        "countryPhoneCode": "241"
    },
    {
        "CountryId": "253",
        "Country": "United Kingdom",
        "FIPS104": "UK",
        "ISO2": "GB",
        "countryPhoneCode": "44"
    },
    {
        "CountryId": "97",
        "Country": "Grenada",
        "FIPS104": "GJ",
        "ISO2": "GD",
        "countryPhoneCode": "1473"
    },
    {
        "CountryId": "90",
        "Country": "Georgia",
        "FIPS104": "GG",
        "ISO2": "GE",
        "countryPhoneCode": "995"
    },
    {
        "CountryId": "84",
        "Country": "French Guiana",
        "FIPS104": "FG",
        "ISO2": "GF",
        "countryPhoneCode": "594"
    },
    {
        "CountryId": "92",
        "Country": "Ghana",
        "FIPS104": "GH",
        "ISO2": "GH",
        "countryPhoneCode": "233"
    },
    {
        "CountryId": "93",
        "Country": "Gibraltar",
        "FIPS104": "GI",
        "ISO2": "GI",
        "countryPhoneCode": "350"
    },
    {
        "CountryId": "96",
        "Country": "Greenland",
        "FIPS104": "GL",
        "ISO2": "GL",
        "countryPhoneCode": "299"
    },
    {
        "CountryId": "88",
        "Country": "The Gambia",
        "FIPS104": "GA",
        "ISO2": "GM",
        "countryPhoneCode": "220"
    },
    {
        "CountryId": "102",
        "Country": "Guinea",
        "FIPS104": "GV",
        "ISO2": "GN",
        "countryPhoneCode": "224"
    },
    {
        "CountryId": "98",
        "Country": "Guadeloupe",
        "FIPS104": "GP",
        "ISO2": "GP",
        "countryPhoneCode": "590"
    },
    {
        "CountryId": "73",
        "Country": "Equatorial Guinea",
        "FIPS104": "EK",
        "ISO2": "GQ",
        "countryPhoneCode": "240"
    },
    {
        "CountryId": "95",
        "Country": "Greece",
        "FIPS104": "GR",
        "ISO2": "GR",
        "countryPhoneCode": "30"
    },
    {
        "CountryId": "100",
        "Country": "Guatemala",
        "FIPS104": "GT",
        "ISO2": "GT",
        "countryPhoneCode": "502"
    },
    {
        "CountryId": "99",
        "Country": "Guam",
        "FIPS104": "GQ",
        "ISO2": "GU",
        "countryPhoneCode": "1671"
    },
    {
        "CountryId": "103",
        "Country": "Guinea-Bissau",
        "FIPS104": "PU",
        "ISO2": "GW",
        "countryPhoneCode": "245"
    },
    {
        "CountryId": "104",
        "Country": "Guyana",
        "FIPS104": "GY",
        "ISO2": "GY",
        "countryPhoneCode": "592"
    },
    {
        "CountryId": "109",
        "Country": "Hong Kong (SAR)",
        "FIPS104": "HK",
        "ISO2": "HK",
        "countryPhoneCode": "852"
    },
    {
        "CountryId": "108",
        "Country": "Honduras",
        "FIPS104": "HO",
        "ISO2": "HN",
        "countryPhoneCode": "504"
    },
    {
        "CountryId": "61",
        "Country": "Croatia",
        "FIPS104": "HR",
        "ISO2": "HR",
        "countryPhoneCode": "385"
    },
    {
        "CountryId": "105",
        "Country": "Haiti",
        "FIPS104": "HA",
        "ISO2": "HT",
        "countryPhoneCode": "509"
    },
    {
        "CountryId": "111",
        "Country": "Hungary",
        "FIPS104": "HU",
        "ISO2": "HU",
        "countryPhoneCode": "36"
    },
    {
        "CountryId": "114",
        "Country": "Indonesia",
        "FIPS104": "ID",
        "ISO2": "ID",
        "countryPhoneCode": "62"
    },
    {
        "CountryId": "117",
        "Country": "Ireland",
        "FIPS104": "EI",
        "ISO2": "IE",
        "countryPhoneCode": "353"
    },
    {
        "CountryId": "118",
        "Country": "Israel",
        "FIPS104": "IS",
        "ISO2": "IL",
        "countryPhoneCode": "972"
    },
    {
        "CountryId": "113",
        "Country": "India",
        "FIPS104": "IN",
        "ISO2": "IN",
        "countryPhoneCode": "91"
    },
    {
        "CountryId": "34",
        "Country": "British Indian Ocean Territory",
        "FIPS104": "IO",
        "ISO2": "IO",
        "countryPhoneCode": "246"
    },
    {
        "CountryId": "116",
        "Country": "Iraq",
        "FIPS104": "IZ",
        "ISO2": "IQ",
        "countryPhoneCode": "964"
    },
    {
        "CountryId": "115",
        "Country": "Iran",
        "FIPS104": "IR",
        "ISO2": "IR",
        "countryPhoneCode": "98"
    },
    {
        "CountryId": "112",
        "Country": "Iceland",
        "FIPS104": "IC",
        "ISO2": "IS",
        "countryPhoneCode": "354"
    },
    {
        "CountryId": "119",
        "Country": "Italy",
        "FIPS104": "IT",
        "ISO2": "IT",
        "countryPhoneCode": "39"
    },
    {
        "CountryId": "120",
        "Country": "Jamaica",
        "FIPS104": "JM",
        "ISO2": "JM",
        "countryPhoneCode": "1876"
    },
    {
        "CountryId": "126",
        "Country": "Jordan",
        "FIPS104": "JO",
        "ISO2": "JO",
        "countryPhoneCode": "962"
    },
    {
        "CountryId": "122",
        "Country": "Japan",
        "FIPS104": "JA",
        "ISO2": "JP",
        "countryPhoneCode": "81"
    },
    {
        "CountryId": "129",
        "Country": "Kenya",
        "FIPS104": "KE",
        "ISO2": "KE",
        "countryPhoneCode": "254"
    },
    {
        "CountryId": "135",
        "Country": "Kyrgyzstan",
        "FIPS104": "KG",
        "ISO2": "KG",
        "countryPhoneCode": "996"
    },
    {
        "CountryId": "41",
        "Country": "Cambodia",
        "FIPS104": "CB",
        "ISO2": "KH",
        "countryPhoneCode": "855"
    },
    {
        "CountryId": "131",
        "Country": "Kiribati",
        "FIPS104": "KR",
        "ISO2": "KI",
        "countryPhoneCode": "686"
    },
    {
        "CountryId": "54",
        "Country": "Comoros",
        "FIPS104": "CN",
        "ISO2": "KM",
        "countryPhoneCode": "269"
    },
    {
        "CountryId": "206",
        "Country": "Saint Kitts and Nevis",
        "FIPS104": "SC",
        "ISO2": "KN",
        "countryPhoneCode": "1869"
    },
    {
        "CountryId": "132",
        "Country": "Korea, North",
        "FIPS104": "KN",
        "ISO2": "KP",
        "countryPhoneCode": "850"
    },
    {
        "CountryId": "133",
        "Country": "Korea, South",
        "FIPS104": "KS",
        "ISO2": "KR",
        "countryPhoneCode": "82"
    },
    {
        "CountryId": "134",
        "Country": "Kuwait",
        "FIPS104": "KU",
        "ISO2": "KW",
        "countryPhoneCode": "965"
    },
    {
        "CountryId": "45",
        "Country": "Cayman Islands",
        "FIPS104": "CJ",
        "ISO2": "KY",
        "countryPhoneCode": "1345"
    },
    {
        "CountryId": "128",
        "Country": "Kazakhstan",
        "FIPS104": "KZ",
        "ISO2": "KZ",
        "countryPhoneCode": "7"
    },
    {
        "CountryId": "136",
        "Country": "Laos",
        "FIPS104": "LA",
        "ISO2": "LA",
        "countryPhoneCode": "856"
    },
    {
        "CountryId": "138",
        "Country": "Lebanon",
        "FIPS104": "LE",
        "ISO2": "LB",
        "countryPhoneCode": "961"
    },
    {
        "CountryId": "207",
        "Country": "Saint Lucia",
        "FIPS104": "ST",
        "ISO2": "LC",
        "countryPhoneCode": "1758"
    },
    {
        "CountryId": "142",
        "Country": "Liechtenstein",
        "FIPS104": "LS",
        "ISO2": "LI",
        "countryPhoneCode": "423"
    },
    {
        "CountryId": "228",
        "Country": "Sri Lanka",
        "FIPS104": "CE",
        "ISO2": "LK",
        "countryPhoneCode": "94"
    },
    {
        "CountryId": "140",
        "Country": "Liberia",
        "FIPS104": "LI",
        "ISO2": "LR",
        "countryPhoneCode": "231"
    },
    {
        "CountryId": "139",
        "Country": "Lesotho",
        "FIPS104": "LT",
        "ISO2": "LS",
        "countryPhoneCode": "266"
    },
    {
        "CountryId": "143",
        "Country": "Lithuania",
        "FIPS104": "LH",
        "ISO2": "LT",
        "countryPhoneCode": "370"
    },
    {
        "CountryId": "144",
        "Country": "Luxembourg",
        "FIPS104": "LU",
        "ISO2": "LU",
        "countryPhoneCode": "352"
    },
    {
        "CountryId": "137",
        "Country": "Latvia",
        "FIPS104": "LG",
        "ISO2": "LV",
        "countryPhoneCode": "371"
    },
    {
        "CountryId": "141",
        "Country": "Libya",
        "FIPS104": "LY",
        "ISO2": "LY",
        "countryPhoneCode": "218"
    },
    {
        "CountryId": "168",
        "Country": "Morocco",
        "FIPS104": "MO",
        "ISO2": "MA",
        "countryPhoneCode": "212"
    },
    {
        "CountryId": "164",
        "Country": "Monaco",
        "FIPS104": "MN",
        "ISO2": "MC",
        "countryPhoneCode": "377"
    },
    {
        "CountryId": "163",
        "Country": "Moldova",
        "FIPS104": "MD",
        "ISO2": "MD",
        "countryPhoneCode": "373"
    },
    {
        "CountryId": "147",
        "Country": "Madagascar",
        "FIPS104": "MA",
        "ISO2": "MG",
        "countryPhoneCode": "261"
    },
    {
        "CountryId": "154",
        "Country": "Marshall Islands",
        "FIPS104": "RM",
        "ISO2": "MH",
        "countryPhoneCode": "692"
    },
    {
        "CountryId": "151",
        "Country": "Mali",
        "FIPS104": "ML",
        "ISO2": "ML",
        "countryPhoneCode": "223"
    },
    {
        "CountryId": "39",
        "Country": "Burma",
        "FIPS104": "BM",
        "ISO2": "MM",
        "countryPhoneCode": "95"
    },
    {
        "CountryId": "165",
        "Country": "Mongolia",
        "FIPS104": "MG",
        "ISO2": "MN",
        "countryPhoneCode": "976"
    },
    {
        "CountryId": "145",
        "Country": "Macao",
        "FIPS104": "MC",
        "ISO2": "MO",
        "countryPhoneCode": "853"
    },
    {
        "CountryId": "184",
        "Country": "Northern Mariana Islands",
        "FIPS104": "CQ",
        "ISO2": "MP",
        "countryPhoneCode": "1670"
    },
    {
        "CountryId": "155",
        "Country": "Martinique",
        "FIPS104": "MB",
        "ISO2": "MQ",
        "countryPhoneCode": "596"
    },
    {
        "CountryId": "156",
        "Country": "Mauritania",
        "FIPS104": "MR",
        "ISO2": "MR",
        "countryPhoneCode": "222"
    },
    {
        "CountryId": "167",
        "Country": "Montserrat",
        "FIPS104": "MH",
        "ISO2": "MS",
        "countryPhoneCode": "1664"
    },
    {
        "CountryId": "152",
        "Country": "Malta",
        "FIPS104": "MT",
        "ISO2": "MT",
        "countryPhoneCode": "356"
    },
    {
        "CountryId": "157",
        "Country": "Mauritius",
        "FIPS104": "MP",
        "ISO2": "MU",
        "countryPhoneCode": "230"
    },
    {
        "CountryId": "150",
        "Country": "Maldives",
        "FIPS104": "MV",
        "ISO2": "MV",
        "countryPhoneCode": "960"
    },
    {
        "CountryId": "148",
        "Country": "Malawi",
        "FIPS104": "MI",
        "ISO2": "MW",
        "countryPhoneCode": "265"
    },
    {
        "CountryId": "159",
        "Country": "Mexico",
        "FIPS104": "MX",
        "ISO2": "MX",
        "countryPhoneCode": "52"
    },
    {
        "CountryId": "149",
        "Country": "Malaysia",
        "FIPS104": "MY",
        "ISO2": "MY",
        "countryPhoneCode": "60"
    },
    {
        "CountryId": "169",
        "Country": "Mozambique",
        "FIPS104": "MZ",
        "ISO2": "MZ",
        "countryPhoneCode": "258"
    },
    {
        "CountryId": "171",
        "Country": "Namibia",
        "FIPS104": "WA",
        "ISO2": "NA",
        "countryPhoneCode": "264"
    },
    {
        "CountryId": "177",
        "Country": "New Caledonia",
        "FIPS104": "NC",
        "ISO2": "NC",
        "countryPhoneCode": "687"
    },
    {
        "CountryId": "180",
        "Country": "Niger",
        "FIPS104": "NG",
        "ISO2": "NE",
        "countryPhoneCode": "227"
    },
    {
        "CountryId": "183",
        "Country": "Norfolk Island",
        "FIPS104": "NF",
        "ISO2": "NF",
        "countryPhoneCode": "672"
    },
    {
        "CountryId": "181",
        "Country": "Nigeria",
        "FIPS104": "NI",
        "ISO2": "NG",
        "countryPhoneCode": "234"
    },
    {
        "CountryId": "179",
        "Country": "Nicaragua",
        "FIPS104": "NU",
        "ISO2": "NI",
        "countryPhoneCode": "505"
    },
    {
        "CountryId": "175",
        "Country": "Netherlands",
        "FIPS104": "NL",
        "ISO2": "NL",
        "countryPhoneCode": "31"
    },
    {
        "CountryId": "185",
        "Country": "Norway",
        "FIPS104": "NO",
        "ISO2": "NO",
        "countryPhoneCode": "47"
    },
    {
        "CountryId": "174",
        "Country": "Nepal",
        "FIPS104": "NP",
        "ISO2": "NP",
        "countryPhoneCode": "977"
    },
    {
        "CountryId": "172",
        "Country": "Nauru",
        "FIPS104": "NR",
        "ISO2": "NR",
        "countryPhoneCode": "674"
    },
    {
        "CountryId": "182",
        "Country": "Niue",
        "FIPS104": "NE",
        "ISO2": "NU",
        "countryPhoneCode": "683"
    },
    {
        "CountryId": "178",
        "Country": "New Zealand",
        "FIPS104": "NZ",
        "ISO2": "NZ",
        "countryPhoneCode": "64"
    },
    {
        "CountryId": "186",
        "Country": "Oman",
        "FIPS104": "MU",
        "ISO2": "OM",
        "countryPhoneCode": "968"
    },
    {
        "CountryId": "190",
        "Country": "Panama",
        "FIPS104": "PM",
        "ISO2": "PA",
        "countryPhoneCode": "507"
    },
    {
        "CountryId": "194",
        "Country": "Peru",
        "FIPS104": "PE",
        "ISO2": "PE",
        "countryPhoneCode": "51"
    },
    {
        "CountryId": "85",
        "Country": "French Polynesia",
        "FIPS104": "FP",
        "ISO2": "PF",
        "countryPhoneCode": "689"
    },
    {
        "CountryId": "191",
        "Country": "Papua New Guinea",
        "FIPS104": "PP",
        "ISO2": "PG",
        "countryPhoneCode": "675"
    },
    {
        "CountryId": "195",
        "Country": "Philippines",
        "FIPS104": "RP",
        "ISO2": "PH",
        "countryPhoneCode": "63"
    },
    {
        "CountryId": "187",
        "Country": "Pakistan",
        "FIPS104": "PK",
        "ISO2": "PK",
        "countryPhoneCode": "92"
    },
    {
        "CountryId": "197",
        "Country": "Poland",
        "FIPS104": "PL",
        "ISO2": "PL",
        "countryPhoneCode": "48"
    },
    {
        "CountryId": "208",
        "Country": "Saint Pierre and Miquelon",
        "FIPS104": "SB",
        "ISO2": "PM",
        "countryPhoneCode": "508"
    },
    {
        "CountryId": "196",
        "Country": "Pitcairn Islands",
        "FIPS104": "PC",
        "ISO2": "PN",
        "countryPhoneCode": "0"
    },
    {
        "CountryId": "199",
        "Country": "Puerto Rico",
        "FIPS104": "RQ",
        "ISO2": "PR",
        "countryPhoneCode": "1787"
    },
    {
        "CountryId": "275",
        "Country": "Palestinian Territory, Occupied",
        "FIPS104": "--",
        "ISO2": "PS",
        "countryPhoneCode": "970"
    },
    {
        "CountryId": "198",
        "Country": "Portugal",
        "FIPS104": "PO",
        "ISO2": "PT",
        "countryPhoneCode": "351"
    },
    {
        "CountryId": "188",
        "Country": "Palau",
        "FIPS104": "PS",
        "ISO2": "PW",
        "countryPhoneCode": "680"
    },
    {
        "CountryId": "193",
        "Country": "Paraguay",
        "FIPS104": "PA",
        "ISO2": "PY",
        "countryPhoneCode": "595"
    },
    {
        "CountryId": "200",
        "Country": "Qatar",
        "FIPS104": "QA",
        "ISO2": "QA",
        "countryPhoneCode": "974"
    },
    {
        "CountryId": "201",
        "Country": "R\u00c3\u00a9union",
        "FIPS104": "RE",
        "ISO2": "RE",
        "countryPhoneCode": "262"
    },
    {
        "CountryId": "202",
        "Country": "Romania",
        "FIPS104": "RO",
        "ISO2": "RO",
        "countryPhoneCode": "40"
    },
    {
        "CountryId": "203",
        "Country": "Russia",
        "FIPS104": "RS",
        "ISO2": "RU",
        "countryPhoneCode": "70"
    },
    {
        "CountryId": "204",
        "Country": "Rwanda",
        "FIPS104": "RW",
        "ISO2": "RW",
        "countryPhoneCode": "250"
    },
    {
        "CountryId": "213",
        "Country": "Saudi Arabia",
        "FIPS104": "SA",
        "ISO2": "SA",
        "countryPhoneCode": "966"
    },
    {
        "CountryId": "222",
        "Country": "Solomon Islands",
        "FIPS104": "BP",
        "ISO2": "SB",
        "countryPhoneCode": "677"
    },
    {
        "CountryId": "217",
        "Country": "Seychelles",
        "FIPS104": "SE",
        "ISO2": "SC",
        "countryPhoneCode": "248"
    },
    {
        "CountryId": "229",
        "Country": "Sudan",
        "FIPS104": "SU",
        "ISO2": "SD",
        "countryPhoneCode": "249"
    },
    {
        "CountryId": "233",
        "Country": "Sweden",
        "FIPS104": "SW",
        "ISO2": "SE",
        "countryPhoneCode": "46"
    },
    {
        "CountryId": "219",
        "Country": "Singapore",
        "FIPS104": "SN",
        "ISO2": "SG",
        "countryPhoneCode": "65"
    },
    {
        "CountryId": "205",
        "Country": "Saint Helena",
        "FIPS104": "SH",
        "ISO2": "SH",
        "countryPhoneCode": "290"
    },
    {
        "CountryId": "221",
        "Country": "Slovenia",
        "FIPS104": "SI",
        "ISO2": "SI",
        "countryPhoneCode": "386"
    },
    {
        "CountryId": "231",
        "Country": "Svalbard",
        "FIPS104": "SV",
        "ISO2": "SJ",
        "countryPhoneCode": "47"
    },
    {
        "CountryId": "220",
        "Country": "Slovakia",
        "FIPS104": "LO",
        "ISO2": "SK",
        "countryPhoneCode": "421"
    },
    {
        "CountryId": "218",
        "Country": "Sierra Leone",
        "FIPS104": "SL",
        "ISO2": "SL",
        "countryPhoneCode": "232"
    },
    {
        "CountryId": "211",
        "Country": "San Marino",
        "FIPS104": "SM",
        "ISO2": "SM",
        "countryPhoneCode": "378"
    },
    {
        "CountryId": "214",
        "Country": "Senegal",
        "FIPS104": "SG",
        "ISO2": "SN",
        "countryPhoneCode": "221"
    },
    {
        "CountryId": "223",
        "Country": "Somalia",
        "FIPS104": "SO",
        "ISO2": "SO",
        "countryPhoneCode": "252"
    },
    {
        "CountryId": "230",
        "Country": "Suriname",
        "FIPS104": "NS",
        "ISO2": "SR",
        "countryPhoneCode": "597"
    },
    {
        "CountryId": "212",
        "Country": "S\u00c3\u00a3o Tom\u00c3\u00a9 and Pr\u00c3\u00adncipe",
        "FIPS104": "TP",
        "ISO2": "ST",
        "countryPhoneCode": "239"
    },
    {
        "CountryId": "72",
        "Country": "El Salvador",
        "FIPS104": "ES",
        "ISO2": "SV",
        "countryPhoneCode": "503"
    },
    {
        "CountryId": "235",
        "Country": "Syria",
        "FIPS104": "SY",
        "ISO2": "SY",
        "countryPhoneCode": "963"
    },
    {
        "CountryId": "232",
        "Country": "Swaziland",
        "FIPS104": "WZ",
        "ISO2": "SZ",
        "countryPhoneCode": "268"
    },
    {
        "CountryId": "248",
        "Country": "Turks and Caicos Islands",
        "FIPS104": "TK",
        "ISO2": "TC",
        "countryPhoneCode": "1649"
    },
    {
        "CountryId": "47",
        "Country": "Chad",
        "FIPS104": "CD",
        "ISO2": "TD",
        "countryPhoneCode": "235"
    },
    {
        "CountryId": "240",
        "Country": "Togo",
        "FIPS104": "TO",
        "ISO2": "TG",
        "countryPhoneCode": "228"
    },
    {
        "CountryId": "239",
        "Country": "Thailand",
        "FIPS104": "TH",
        "ISO2": "TH",
        "countryPhoneCode": "66"
    },
    {
        "CountryId": "237",
        "Country": "Tajikistan",
        "FIPS104": "TI",
        "ISO2": "TJ",
        "countryPhoneCode": "992"
    },
    {
        "CountryId": "241",
        "Country": "Tokelau",
        "FIPS104": "TL",
        "ISO2": "TK",
        "countryPhoneCode": "690"
    },
    {
        "CountryId": "69",
        "Country": "East Timor",
        "FIPS104": "TT",
        "ISO2": "TL",
        "countryPhoneCode": "670"
    },
    {
        "CountryId": "247",
        "Country": "Turkmenistan",
        "FIPS104": "TX",
        "ISO2": "TM",
        "countryPhoneCode": "7370"
    },
    {
        "CountryId": "245",
        "Country": "Tunisia",
        "FIPS104": "TS",
        "ISO2": "TN",
        "countryPhoneCode": "216"
    },
    {
        "CountryId": "242",
        "Country": "Tonga",
        "FIPS104": "TN",
        "ISO2": "TO",
        "countryPhoneCode": "676"
    },
    {
        "CountryId": "246",
        "Country": "Turkey",
        "FIPS104": "TU",
        "ISO2": "TR",
        "countryPhoneCode": "90"
    },
    {
        "CountryId": "243",
        "Country": "Trinidad and Tobago",
        "FIPS104": "TD",
        "ISO2": "TT",
        "countryPhoneCode": "1868"
    },
    {
        "CountryId": "249",
        "Country": "Tuvalu",
        "FIPS104": "TV",
        "ISO2": "TV",
        "countryPhoneCode": "688"
    },
    {
        "CountryId": "236",
        "Country": "Taiwan",
        "FIPS104": "TW",
        "ISO2": "TW",
        "countryPhoneCode": "886"
    },
    {
        "CountryId": "238",
        "Country": "Tanzania",
        "FIPS104": "TZ",
        "ISO2": "TZ",
        "countryPhoneCode": "255"
    },
    {
        "CountryId": "251",
        "Country": "Ukraine",
        "FIPS104": "UP",
        "ISO2": "UA",
        "countryPhoneCode": "380"
    },
    {
        "CountryId": "250",
        "Country": "Uganda",
        "FIPS104": "UG",
        "ISO2": "UG",
        "countryPhoneCode": "256"
    },
    {
        "CountryId": "254",
        "Country": "United States",
        "FIPS104": "US",
        "ISO2": "US",
        "countryPhoneCode": "1"
    },
    {
        "CountryId": "256",
        "Country": "Uruguay",
        "FIPS104": "UY",
        "ISO2": "UY",
        "countryPhoneCode": "598"
    },
    {
        "CountryId": "257",
        "Country": "Uzbekistan",
        "FIPS104": "UZ",
        "ISO2": "UZ",
        "countryPhoneCode": "998"
    },
    {
        "CountryId": "107",
        "Country": "Holy See (Vatican City)",
        "FIPS104": "VT",
        "ISO2": "VA",
        "countryPhoneCode": "39"
    },
    {
        "CountryId": "209",
        "Country": "Saint Vincent and the Grenadines",
        "FIPS104": "VC",
        "ISO2": "VC",
        "countryPhoneCode": "1784"
    },
    {
        "CountryId": "259",
        "Country": "Venezuela",
        "FIPS104": "VE",
        "ISO2": "VE",
        "countryPhoneCode": "58"
    },
    {
        "CountryId": "35",
        "Country": "British Virgin Islands",
        "FIPS104": "VI",
        "ISO2": "VG",
        "countryPhoneCode": "1284"
    },
    {
        "CountryId": "261",
        "Country": "Virgin Islands",
        "FIPS104": "VQ",
        "ISO2": "VI",
        "countryPhoneCode": "1340"
    },
    {
        "CountryId": "260",
        "Country": "Vietnam",
        "FIPS104": "VM",
        "ISO2": "VN",
        "countryPhoneCode": "84"
    },
    {
        "CountryId": "258",
        "Country": "Vanuatu",
        "FIPS104": "NH",
        "ISO2": "VU",
        "countryPhoneCode": "678"
    },
    {
        "CountryId": "265",
        "Country": "Wallis and Futuna",
        "FIPS104": "WF",
        "ISO2": "WF",
        "countryPhoneCode": "681"
    },
    {
        "CountryId": "210",
        "Country": "Samoa",
        "FIPS104": "WS",
        "ISO2": "WS",
        "countryPhoneCode": "684"
    },
    {
        "CountryId": "270",
        "Country": "Yemen",
        "FIPS104": "YM",
        "ISO2": "YE",
        "countryPhoneCode": "967"
    },
    {
        "CountryId": "158",
        "Country": "Mayotte",
        "FIPS104": "MF",
        "ISO2": "YT",
        "countryPhoneCode": "269"
    },
    {
        "CountryId": "224",
        "Country": "South Africa",
        "FIPS104": "SF",
        "ISO2": "ZA",
        "countryPhoneCode": "27"
    },
    {
        "CountryId": "273",
        "Country": "Zambia",
        "FIPS104": "ZA",
        "ISO2": "ZM",
        "countryPhoneCode": "260"
    },
    {
        "CountryId": "274",
        "Country": "Zimbabwe",
        "FIPS104": "ZI",
        "ISO2": "ZW",
        "countryPhoneCode": "263"
    }
];