import { createSlice } from "@reduxjs/toolkit";

export const UpcomingVisitSlice = createSlice({
    name: "UpcomingVisit",
    initialState: {
        Visits: [],
        Slots: [],
        PhoneCalls: [],
    },
    reducers: {
        setVisits: (state, action) => {
            state.Visits = action.payload;
        },
        setSlots: (state, action) => {
            state.Slots = action.payload;
        },
        setPhoneCalls: (state, action) => {
            state.PhoneCalls = action.payload;
        },
    },
});

export const { setVisits, setSlots, setPhoneCalls } =
    UpcomingVisitSlice.actions;

export const UpcomingVisitReducer = UpcomingVisitSlice.reducer;
