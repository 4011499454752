
const VERSION_NUMBER = "v8_1";


export const getBaseURL = () => {

    try {
        if (window.location.hostname == "localhost") {
            return `https://www.myhomept.co/PT2/httpdocs/${VERSION_NUMBER}`;
            return `http://localhost/PT2/httpdocs/${VERSION_NUMBER}`;
            return `https://beta.myhomept.co/PT2/httpdocs/${VERSION_NUMBER}`;
            return `https://preprovider.bluejayhealth.com/PT2/httpdocs/${VERSION_NUMBER}`;
            // return `https://provider.bluejayhealth.com/PT2/httpdocs/${VERSION_NUMBER}`;


        }
        if (window.location.hostname == "chimepatient.myhomept.co") {

            return `https://www.myhomept.co/PT2/httpdocs/${VERSION_NUMBER}`;
        }
        if (window.location.hostname == "patient.myhomept.co") {

            return `https://www.myhomept.co/PT2/httpdocs/${VERSION_NUMBER}`;

        }
        if (window.location.hostname == "betapatient.myhomept.co") {

            return `https://beta.myhomept.co/PT2/httpdocs/${VERSION_NUMBER}`;

        }
        if (window.location.hostname == "prepatient.bluejayhealth.com") {

            return `https://preprodprovider.bluejayhealth.com/PT2/httpdocs/${VERSION_NUMBER}`;

        }
        if (window.location.hostname == "patient.bluejayhealth.com") {

            return `https://provider.bluejayhealth.com/PT2/httpdocs/${VERSION_NUMBER}`;

        }
    } catch (error) {
        console.log("Some Error Occured");
        console.log(error);

    }
};


export const getOnlyBaseURL = () => {

    try {
        if (window.location.hostname == "localhost") {

            return "https://www.myhomept.co/PT2/httpdocs";
            // return "http://localhost/PT2/httpdocs";
        }
        if (window.location.hostname == "chimepatient.myhomept.co") {

            return "https://www.myhomept.co/PT2/httpdocs";
        }
        if (window.location.hostname == "patient.myhomept.co") {

            return "https://www.myhomept.co/PT2/httpdocs";

        }
        if (window.location.hostname == "betapatient.myhomept.co") {

            return "https://beta.myhomept.co/PT2/httpdocs";

        }
        if (window.location.hostname == "prepatient.bluejayhealth.com") {

            return "https://preprodprovider.bluejayhealth.com/PT2/httpdocs";

        }
        if (window.location.hostname == "patient.bluejayhealth.com") {

            return "https://provider.bluejayhealth.com/PT2/httpdocs";

        }
    } catch (error) {
        console.log("Some Error Occured");
        console.log(error);

    }
};


export const getMeetingServerURL = () => {

    try {
        if (window.location.hostname === "localhost") {

            return "https://chimetest.myhomept.co";
        }
        if (window.location.hostname == "chimepatient.myhomept.co") {

            return "https://chimetest.myhomept.co";
        }
        if (window.location.hostname == "patient.myhomept.co") {

            return "https://chimetest.myhomept.co";

        }
        if (window.location.hostname == "betapatient.myhomept.co") {

            return "https://betameeting.myhomept.co";

        }
        if (window.location.hostname == "prepatient.bluejayhealth.com") {

            return "https://premeeting.bluejayhealth.com";

        }
        if (window.location.hostname == "patient.bluejayhealth.com") {

            return "https://meeting.bluejayhealth.com";

        }
    } catch (error) {
        console.log("Some Error Occured");
        console.log(error);

    }
};