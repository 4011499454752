import React from "react";
// import PropTypes from "prop-types";
import GoalsAndOutcomesScreen from "../components/goalsAndOutcomes/GoalsAndOutcomesScreen";
const GoalsAnOutcomes = props => {
  
    return (
        <GoalsAndOutcomesScreen/>
    );
};
GoalsAnOutcomes.propTypes = {

};

export default GoalsAnOutcomes;
