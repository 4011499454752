import React, { useState } from "react";
import { DARK_BLUE } from "../../constants";
import moment from "moment";
import { Button } from "@mui/material";

import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions";

const DashboardTask = ({
    SelectedModule,
    SelectedModuleData,
    IsPhoneCall = false
}) => {


    const dispatch = useDispatch();
    const [IsLoading, setIsLoading] = useState(false);


    async function markAsComplete() {
        setIsLoading(true);
        await dispatch(Actions.markAsComplete(SelectedModule.programModuleId, 100));
        setIsLoading(false);

        window.location.reload();
    }


    return (
        <div className="col-lg-7 col-md-12 mb-2" >
            <div className="card" style={{ border: 0 }} >
                <div className="d-flex align-items-center justify-content-between py-3 px-3"
                    style={{ fontSize: "14px", fontWeight: "500", borderBottom: "1px solid #e4ebf3" }}
                >
                    <span style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: "500" }} >
                        {SelectedModule.moduleTitle}
                    </span>
                    <div className="d-flex align-items-center" >
                        <span className="mx-1" style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: 500 }} >Status:</span>
                        <div>
                            {
                                SelectedModule?.programModuleStatus && SelectedModule.programModuleStatus[0] != 0 ? (
                                    <div className="d-flex align-items-center" >
                                        <div className="mx-2" style={{ backgroundColor: "#009704", height: "5px", width: "5px", borderRadius: "100%" }} ></div>
                                        <span>Complete</span>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center" >
                                        <div className="mx-2" style={{ backgroundColor: "#eb0400", height: "5px", width: "5px", borderRadius: "100%" }} ></div>
                                        <span>Incomplete</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="row py-2 px-3">
                    <div className="col-4" style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}>
                        Task Name:
                    </div>
                    <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
                        {SelectedModule.moduleTitle}
                    </div>

                    {/* displaying time as well for phone call */}

                    <div className="col-4" style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}>
                        Start Date:
                    </div>
                    <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
                        {
                            IsPhoneCall ?
                                (moment(SelectedModuleData[0].startDate * 1000).format("MMMM DD, YYYY hh:mm A")) :
                                (moment(SelectedModuleData[0].startDate * 1000).format("MMMM DD, YYYY"))
                        }
                    </div>
                    <div className="col-4" style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}>
                        End Date:
                    </div>
                    <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
                        {
                            IsPhoneCall ?
                                (moment(SelectedModuleData[0].endDate * 1000).format("MMMM DD, YYYY hh:mm A")) :
                                (moment(SelectedModuleData[0].endDate * 1000).format("MMMM DD, YYYY"))
                        }
                    </div>
                    {
                        !IsPhoneCall && SelectedModuleData.map((value, index) => {
                            return (
                                <>
                                    <div className="col-4" style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}>
                                        Task no {index + 1}:
                                    </div>
                                    <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
                                        {value.description}
                                    </div>
                                </>
                            );
                        })
                    }
                    {/* {
                        SelectedModule?.programModuleStatus && SelectedModule.programModuleStatus[0] != 0 ? null : (
                            <Button
                                variant="outlined"
                                className="mt-3 ml-3"
                                onClick={() => markAsComplete()}
                                disabled={
                                    IsPhoneCall ?
                                        (moment().isBefore(moment(SelectedModuleData[0].startDate * 1000))) :
                                        (moment().isBefore(moment(SelectedModuleData[0].startDate * 1000)))
                                }
                            >
                                Mark as Complete
                            </Button>
                        )
                    } */}



                </div>
            </div>

        </div>
    );
};

export default DashboardTask;