import { createSlice } from "@reduxjs/toolkit";

export const ProgramsSlice = createSlice({
    name: "Programs",
    initialState: {
        Programs: [],
        ProgramDetails: [],
        ModuleDetails: [],
        AllJoinedProgramsFromThisUser: [],

    },
    reducers: {
        setPrograms: (state, action) => {
            state.Programs = action.payload;
        },
        setProgramDetails: (state, action) => {
            state.ProgramDetails = action.payload;
        },
        setModuleDetails: (state, action) => {
            state.ModuleDetails = action.payload;
        },
        setAllJoinedProgramsFromThisUser: (state, action) => {
            state.AllJoinedProgramsFromThisUser = action.payload;
        },


    },
});

export const { setPrograms, setProgramDetails, setModuleDetails, setAllJoinedProgramsFromThisUser } = ProgramsSlice.actions;


export const ProgramsReducer = ProgramsSlice.reducer;