
// import "date-fns";
import DatePicker from "@mui/lab/DatePicker";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    LinearProgress,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Rating from "@mui/material/Rating";
import Slider from "@mui/material/Slider";
// import { withStyles } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import HTMLReactParser from "html-react-parser";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useScreenshot } from "use-react-screenshot";
import { v4 as uuidv4 } from "uuid";
import BodyParts from "../../assests/images/Human-body-lines-final.png";
import { DARK_BLUE } from "../../constants";
import * as Actions from "../../store/actions";

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    textColor: {
        color: "#2570B7"
    }
});

const useStylesForRange = makeStyles((theme) => ({
    root: {
        width: 300 + theme.spacing(3) * 2,
    },
    margin: {
        height: theme.spacing(3),
    },
}));
// const PrettoSlider = withStyles({
//     root: {
//         color: "rgb(37, 112, 183)",
//         // height: 8,
//         "&$vertical": {
//             width: 8
//         }
//     },
//     thumb: {
//         height: 10,
//         width: 10,
//         backgroundColor: "#fff",
//         border: "2px solid currentColor",
//         marginTop: -8,
//         marginLeft: -12,
//         "&:focus, &:hover": {
//             boxShadow: "0px 0px 0px 8px currentColor"
//         },
//         "&$active": {
//             boxShadow: "0px 0px 0px 12px currentColor"
//         }
//     },
//     active: {},
//     valueLabel: {
//         left: "-200%"
//     },
//     track: {
//         height: 8,
//         // borderRadius: 4
//     },
//     rail: {
//         height: 8,
//         // borderRadius: 4
//     },
//     vertical: {
//         "& $rail": {
//             width: 8
//         },
//         "& $track": {
//             width: 8
//         },
//         "& $thumb": {
//             marginLeft: -1,
//             marginBottom: -11
//         }
//     },
// })(Slider);

const SurveryDialogContent = props => {


    const dispatch = useDispatch();
    const classes = useStyles();
    // const classesForRange = useStylesForRange();
    const bodyPainRef = useRef();

    const [image, takeScreenshot] = useScreenshot();
    const getImage = () => takeScreenshot(bodyPainRef.current);


    const [PainLocationPointsAndData, setPainLocationPointsAndData] = useState([]);

    // const [YesTextEntryState, setYesTextEntryState] = useState("");

    const [AllAnswers, setAllAnswers] = useState([]);

    const [UseLessState, setUseLessState] = useState(1);
    // const [ForTheRadioTypes, setForTheRadioTypes] = useState([]);
    const [ForTheDatePicker, setForTheDatePicker] = useState(new Date());
    const [ForScale, setForScale] = useState(1);
    const [ForTextInputs, setForTextInputs] = useState([]);
    const [ForRadio, setForRadio] = useState("");
    const [ForCheckBox, setForCheckBox] = useState([]);
    const [ForRating, setForRating] = useState(1);
    const [YesTextEntryRadio, setYesTextEntryRadio] = useState(null);
    const [YesText, setYesText] = useState("");
    const [UseLess, setUseLess] = useState(1);


    const [TextError, setTextError] = useState("");
    const [RadioError, setRadioError] = useState("");
    const [CheckBoxError, setCheckBoxError] = useState("");
    const [YesTextEntryError, setYesTextEntryError] = useState("");
    const [BodyPainError, setBodyPainError] = useState("");
    const [IsBodyPainLoading, setIsBodyPainLoading] = useState(false);


    useEffect(() => {
        setBodyPainError("");
    }, [PainLocationPointsAndData]);


    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    }

    async function nextQuestion() {
        try {


            setTextError("");
            setRadioError("");
            setCheckBoxError("");
            setYesTextEntryError("");
            setBodyPainError("");


            const isSkipable = props.CurrentQuestion?.Question?.realQuestionTypeId == "4";

            const QuestionID = props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId;
            let newAllQuestions = AllAnswers;

            if(isSkipable){
                newAllQuestions[props.CurrentQuestionIndex] = {
                    "questionId": props.CurrentQuestion.Question.questionId,
                    "value": "",
                    "value2": "",
                    "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                    "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                    "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                    "answers": [
                        ""
                    ]
                };
            }else{
                if (QuestionID == 1) {
                    setForRadio("");
                    setRadioError("");
                    if (!ForRadio) return setRadioError("Please select a value.");
    
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": ForRadio,
                        "value2": ForRadio,
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [
                            ForRadio
                        ]
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForRadio("");
                    setRadioError("");
                }
    
                if (QuestionID == 3) {
                    setForCheckBox([]);
                    if (ForCheckBox.length == 0) return setCheckBoxError("Please select at least one value");
    
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": ForCheckBox.join(", "),
                        "value2": ForCheckBox.join(", "),
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": ForCheckBox
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForCheckBox([]);
                }
    
                if (QuestionID == 13) {
                    if (new Date(ForTheDatePicker) === "Invalid Date") return toast.error("Please select date");
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": new Date(ForTheDatePicker)?.toISOString(),
                        "value2": new Date(ForTheDatePicker)?.toISOString(),
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [
                            new Date(ForTheDatePicker)?.toISOString()
                        ]
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForTheDatePicker(new Date());
                }
    
                if (QuestionID == 10 || QuestionID == 4) {
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": ForScale,
                        "value2": ForScale,
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [
                            ForScale
                        ]
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForScale(1);
                }
                if (QuestionID == 12 || QuestionID == 11 || QuestionID == 2) {
                    setTextError("");
    
                    for (let index = 0; index < document.querySelectorAll("#TheTextFields").length; index++) {
                        const element = document.querySelectorAll("#TheTextFields")[index];
                        if (!element.value) return setTextError("Please fill all the fields.");
    
                    }
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": ForTextInputs.length == 1 ? ForTextInputs[0] : "",
                        "value2": ForTextInputs.length == 1 ? ForTextInputs[0] : "",
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": ForTextInputs
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForTextInputs(prevx => []);
                    document.querySelectorAll("#TheTextFields").forEach((value, index) => {
                        value.value = "";
                    });
                    setUseLessState(prevx => prevx + 1);
                }
    
    
                if (QuestionID == 18) {
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": ForRating,
                        "value2": ForRating,
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [
                            ForRating
                        ]
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setForRating(1);
                }
    
    
    
                if (QuestionID == 20) {
    
    
                    setBodyPainError("");
    
                    if (PainLocationPointsAndData.length == 0) return setBodyPainError("Please select the area of pain.");
    
                    if (PainLocationPointsAndData.length == 1) {
                        if (PainLocationPointsAndData[0].checkBoxes.length == 0) return setBodyPainError("Please select the quality of your pain.");
                    } else {
                        for (let index = 0; index < PainLocationPointsAndData.length; index++) {
                            const element = PainLocationPointsAndData[index];
                            if (element.checkBoxes.length == 0) {
                                return setBodyPainError(`Please select the quality of your pain # ${index + 1}`);
                                break;
                            }
                        }
                    }
    
                    setIsBodyPainLoading(true);
                    const resImage = await getImage();
                    const myBlob = DataURIToBlob(resImage);
                    const URL = await dispatch(Actions.UploadFile(myBlob, 0, true));
                    setIsBodyPainLoading(false);
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": URL,
                        "value2": URL,
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [URL]
                    };
                    setAllAnswers(prevx => newAllQuestions);
                    setBodyPainError("");
    
                }
    
    
                if (QuestionID == 21) {
                    setYesTextEntryError("");
                    if (YesTextEntryRadio !== false && YesTextEntryRadio !== true) return setYesTextEntryError("Please select a value.");
                    if (YesTextEntryRadio && !YesText) return setYesTextEntryError("Please fill the required field.");
                    newAllQuestions[props.CurrentQuestionIndex] = {
                        "questionId": props.CurrentQuestion.Question.questionId,
                        "value": YesText || "No",
                        "value2": YesTextEntryRadio || "No",
                        "userSypLogRelId": props.CurrentQuestion.userSypLogRelId,
                        "answerChoiceId": props.CurrentQuestion.AnswerChoice[0].answerChoiceId,
                        "answerTypeId": props.CurrentQuestion.AnswerChoice[0].answerTypeId,
                        "answers": [YesTextEntryRadio, YesText]
                    };
                    setAllAnswers(prevx => newAllQuestions);
    
                    setYesTextEntryError("");
                    setYesTextEntryRadio(null);
                    setYesText("");
                }
            }
          

            const nextIndex = props.CurrentQuestionIndex + 1;

            if (AllAnswers[nextIndex]) {
                const nextAnswer = AllAnswers[nextIndex];
                const nextAnswerType = nextAnswer.answerTypeId;
                if (nextAnswerType == 1) {
                    setForRadio(nextAnswer.value);
                }
                if (nextAnswerType == 3) {
                    setForCheckBox(nextAnswer.answers);
                }
                if (nextAnswerType == 10 || nextAnswerType == 4) {
                    setForScale(nextAnswer.value);
                }

                if (nextAnswerType == 13) {
                    setForTheDatePicker(nextAnswer.value);
                }

                if (nextAnswerType == 12 || nextAnswerType == 11 || nextAnswerType == 2) {
                    setForTextInputs(nextAnswer.answers);
                }
                if (nextAnswerType == 18) {
                    setForRating(nextAnswer.value);
                }
                if (nextAnswerType == 21) {
                    setYesTextEntryRadio(nextAnswer.value);
                    setYesText(nextAnswer.YesText);

                }
            }

            if (props.Last) {
                props.submitSurvey(newAllQuestions);
                if (props.onFinish) {
                    props.onFinish();
                }
            } else {
                props.moveToNextQuestion();
            }



        } catch (error) {
            console.log(error.toString());
            if (error.toString().includes("RangeError: Invalid time value")) return toast.error("Please select a date");
            toast.error(error.toString());
        }
    }


    async function moveToPrevious() {
        // return
        const currentIndex = props.CurrentQuestionIndex;
        const previousIndex = currentIndex - 1;
        const previousAnswer = AllAnswers[previousIndex];
        const previousAnswerType = previousAnswer.answerTypeId;

        if (previousAnswerType == 1) {
            setForRadio(previousAnswer.value);
        }
        if (previousAnswerType == 3) {
            setForCheckBox(previousAnswer.answers);
        }
        if (previousAnswerType == 10 || previousAnswerType == 4) {
            setForScale(previousAnswer.value);
        }

        if (previousAnswerType == 13) {
            setForTheDatePicker(previousAnswer.value);
        }

        if (previousAnswerType == 12 || previousAnswerType == 11 || previousAnswerType == 2) {
            setForTextInputs(previousAnswer.answers);
        }
        if (previousAnswerType == 18) {
            setForRating(previousAnswer.value);
        }
        if (previousAnswerType == 21) {
            setYesTextEntryRadio(previousAnswer.value);
            setYesText(previousAnswer.YesText);

        }

        props.moveToPreviousQuestion();
    }


    if (props.IsSurveyLoading) return (
        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>
    );
    if (props.ErrorMsg) return (
        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h3 style={{ color: "grey" }}>{props.ErrorMsg}</h3>
        </div>
    );

    if (props.Finished) return (
        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h3 style={{ color: "grey" }}> Thank you to complete the survey! </h3>
        </div>
    );

    const HeaderPart = () => {
        return (
            <>
                <p style={{ fontSize: "14px", color: "#333", fontWeight: "500" }}>
                    Question {props.CurrentQuestionIndex + 1}/{props.TotalQuestions}
                </p>
                <h3 style={{ fontWeight: "500", color: DARK_BLUE }}>
                    {props.CurrentQuestion?.Question?.questionDetail}
                </h3>

                <div className={classes.root} style={{ marginBottom: "30px" }}>
                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={((props.CurrentQuestionIndex + 1) / (props.TotalQuestions)) * 100}
                        style={{ height: "2px", backgroundColor: "#e4ebf3" }}
                    />
                </div>
            </>
        );
    };

    const FooterPart = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                <span>
                    {
                        props.GobackToVideoConsulationForm ? (
                            <Button
                                className="mt-3 "
                                variant="outlined"
                                // disabled
                                onClick={() => props.GobackToVideoConsulationForm()}
                                style={{ background: "white", color: DARK_BLUE, marginLeft: "10px", textTransform: "capitalize", }}
                            >
                                Back to step 1
                            </Button>
                        ) : null
                    }
                </span>
                <span>
                    {
                        props.CurrentQuestionIndex > 0 ? (
                            <Button
                                className="mt-3"
                                variant="outlined"
                                // disabled
                                onClick={() => moveToPrevious()}
                                style={{ background: "white", color: DARK_BLUE, marginLeft: "10px", textTransform: "capitalize" }}
                            >
                                Back
                            </Button>
                        ) : null
                    }
                    {
                        props.CurrentQuestion?.Question?.realQuestionTypeId == "4" ? (
                            <Button
                                className="mt-3"
                                variant="outlined"
                                onClick={() => nextQuestion()}
                                style={{ background: "white", color: DARK_BLUE, marginLeft: "10px", textTransform: "capitalize" }}
                            >
                                Skip 
                            </Button>
                        ) : null
                    }

                    {
                        props.Last ? (
                            <Button
                                className="mt-3"
                                variant="contained"
                                onClick={() => nextQuestion()}
                                style={{ background: DARK_BLUE, color: "white", marginLeft: "10px", textTransform: "capitalize" }}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                className="mt-3"
                                variant="contained"
                                onClick={() => nextQuestion()}
                                style={{ background: DARK_BLUE, color: "white", marginLeft: "10px", textTransform: "capitalize" }}
                            >
                                Next
                            </Button>
                        )
                    }
                </span>
            </div>
        );
    };

    //1,2,,4,,10,11,13,18,,,,

    // the one with radios
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 1) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                <FormControl component="fieldset">
                    <FormLabel color="primary" component="legend">Select</FormLabel>
                    <RadioGroup color="primary" aria-label="gender" name="gender1"  >
                        {
                            props.CurrentQuestion?.AnswerChoice.map((value, index) => {
                                if (value.description.includes("*")) {
                                    const Arr = value.description.split("*");
                                    return (
                                        <>
                                            <FormControlLabel
                                                index={index}
                                                onClick={() => setForRadio(Arr[1])}
                                                color="primary"
                                                value={Arr[1]}
                                                control={
                                                    <Radio
                                                        checked={ForRadio == Arr[1]}
                                                        color="primary"
                                                        onClick={() => setForRadio(Arr[1])}

                                                    />}
                                                label={HTMLReactParser(Arr[1] || "")}
                                            />
                                            {
                                                Arr.map((value2, index2) => {
                                                    if (index2 > 1)
                                                        return (
                                                            <p style={{ marginLeft: "29px" }} key={index2}>
                                                                {HTMLReactParser(value2 || "")}
                                                            </p>
                                                        );
                                                })
                                            }

                                        </>
                                    );
                                }

                                return (
                                    <FormControlLabel
                                        onClick={() => setForRadio(value.description)}
                                        color="primary"
                                        value={value.description}
                                        control={
                                            <Radio
                                                checked={ForRadio == value.description}
                                                color="primary"
                                                onClick={() => setForRadio(value.description)}

                                            />}
                                        label={HTMLReactParser(value.description || "")}
                                    />
                                );
                            })
                        }
                    </RadioGroup>
                </FormControl>
                <p style={{ textAlign: "center", color: "red" }}>
                    {RadioError}
                </p>
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );

    // the one with multiple or single text field
    if (
        props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 2 ||
        props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 11 ||
        props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 12
    ) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                {
                    props.CurrentQuestion?.AnswerChoice.map((value, index) => {
                        return (
                            <>
                                <label>{value.description}</label>
                                <br />
                                <TextField
                                    id="TheTextFields"
                                    key={index}
                                    // label={value.description}
                                    variant="outlined"
                                    style={{ width: props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 12 ? "100%" : "30%", marginBottom: "20px" }}
                                    value={ForTextInputs[index]}
                                    onChange={(e) => {
                                        let TextsArr = ForTextInputs;
                                        TextsArr[index] = e.target.value;
                                        setForTextInputs(prevx => TextsArr);
                                        setUseLessState(prevx => prevx + 1);

                                    }}
                                />
                                <br />

                            </>
                        );
                    })
                }
                <p style={{ textAlign: "center", color: "red" }}>
                    {TextError}
                </p>
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );

    // the one with Checkboxed
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 3) return (
        <>
            <div className="mb-5" >
                <HeaderPart />

                {
                    props.CurrentQuestion?.AnswerChoice.map((value, index) => {
                        return (
                            <>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ForCheckBox.includes(value.description)}
                                            onClick={(e) => {
                                                // const myBool = ForCheckBox.includes(value.description)
                                                if (ForCheckBox.includes(value.description)) {
                                                    setForCheckBox(prevx => prevx.filter(x => x !== value.description));
                                                } else {
                                                    setForCheckBox(prevx => [...prevx, value.description]);
                                                }
                                            }}
                                            name=""
                                            color="primary"
                                            value={value.description}
                                        />
                                    }
                                    label={value.description}
                                />

                                <br />
                            </>
                        );
                    })
                }

                <p style={{ textAlign: "center", color: "red" }}>
                    {CheckBoxError}
                </p>

                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );


    // the one with scale
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 10 || props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 4) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                <div style={{ height: "400px", width: "100%", display: "flex", justifyContent: "center" }}>
                    {/* <PrettoSlider
                        orientation="vertical"
                        min={0}
                        max={10}
                        marks={
                            props.CurrentQuestion?.AnswerChoice[0].unit == "0-10" ?
                                ([{ value: 0, label: props.CurrentQuestion?.AnswerChoice[0].bottomy }, { value: 10, label: props.CurrentQuestion?.AnswerChoice[0].topy }]) :
                                props.CurrentQuestion?.AnswerChoice[0].scaleString ? props.CurrentQuestion?.AnswerChoice[0].scaleString?.split(",").map((value, index) => ({ value: `${index}`, label: `${index} ${value}` })) : props.CurrentQuestion?.AnswerChoice[0].unit?.split(",").map((value, index) => ({ value: `${index}`, label: `${value}` }))
                        }
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        defaultValue={ForScale}
                        value={ForScale}
                        onChange={(e, v) => {
                            setForScale(parseInt(v))
                        }}
                    /> */}
                    <Slider
                        orientation="vertical"
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        defaultValue={ForScale}
                        value={ForScale}
                        onChange={(e, v) => {
                            setForScale(parseInt(v));
                        }}
                        marks={
                            props.CurrentQuestion?.AnswerChoice[0].unit == "0-10" ?
                                ([{ value: 0, label: props.CurrentQuestion?.AnswerChoice[0].bottomy }, { value: 10, label: props.CurrentQuestion?.AnswerChoice[0].topy }]) :
                                props.CurrentQuestion?.AnswerChoice[0].scaleString ? props.CurrentQuestion?.AnswerChoice[0].scaleString?.split(",").map((value, index) => ({ value: `${index}`, label: `${index} ${value}` })) : props.CurrentQuestion?.AnswerChoice[0].unit?.split(",").map((value, index) => ({ value: `${index}`, label: `${value}` }))
                        }
                        min={0}
                        max={10}
                    />
                </div>
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );

    // the one with date picker
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 13) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                {
                    props.CurrentQuestion?.AnswerChoice.map((value, index) => {
                        return (
                            <DatePicker
                                style={{ width: "100%" }}
                                inputVariant="outlined"
                                margin="normal"
                                maxDate={new Date()}
                                id="date-picker-dialog"
                                label={props.CurrentQuestion?.Question?.questionDetail}
                                format="MM/DD/YYYY"
                                value={ForTheDatePicker}
                                onChange={(e) => setForTheDatePicker(e)}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                    />
                                )}
                            />
                        );
                    })
                }
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );



    // the one with rating
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 18) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                <Rating
                    value={ForRating}
                    onChange={(e, v) => {
                        setForRating(v);
                    }}
                    style={{ marginTop: "20px" }}
                />
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );

    // the one with body pain location selector
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 20) return (
        <>
            <div className="mb-5" >
                <HeaderPart />

                <div
                    ref={bodyPainRef}
                    style={{ width: "100%", padding: "10px" }}>
                    <div

                        onClick={(event) => {
                            let bounds = event.target.getBoundingClientRect();
                            let x = event.clientX - (bounds.left - 30);
                            let y = event.clientY - (bounds.top + 10);
                            setPainLocationPointsAndData(
                                [
                                    ...PainLocationPointsAndData,
                                    {
                                        x: x,
                                        y: y,
                                        ID: uuidv4(),
                                        scale: 0,
                                        checkBoxes: [],
                                    }]
                            );
                        }}
                        className="ImageContainer"
                        style={{ width: "100%", display: "flex", justifyContent: "center", position: "relative", }}
                    >
                        {
                            PainLocationPointsAndData?.map((value, index) => {
                                return (
                                    <div

                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setPainLocationPointsAndData(PainLocationPointsAndData.filter(x => x.ID != value.ID));
                                        }}
                                        key={index}
                                        className="TheDot"
                                        style={{
                                            position: "absolute",
                                            height: "30px",
                                            width: "30px",
                                            background: "#ff5b57",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "100%",
                                            color: "white",
                                            top: value.y,
                                            left: value.x,
                                            cursor: "pointer"
                                        }}
                                    >

                                        {index + 1}
                                    </div>
                                );
                            })
                        }
                        <img style={{ width: "90%" }} src={BodyParts} alt={"Body Parts"} />
                    </div>
                    {
                        PainLocationPointsAndData.map((value, index) => {
                            return (
                                <div key={value.ID} style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "#666666" }}>Pain # {index + 1}</h4>
                                    <div className="d-flex justify-content-between align-items-center" >
                                        <p className="my-0" style={{ fontWeight: "550", fontSize: "16px", color: DARK_BLUE }} >Low</p>
                                        <p className="my-0" style={{ fontWeight: "550", fontSize: "16px", color: DARK_BLUE }} >High</p>
                                    </div>
                                    <Slider
                                        defaultValue={1}
                                        // getAriaValueText={valuetext}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        // marks
                                        value={PainLocationPointsAndData.filter(x => x.ID == value.ID)[0].scale}
                                        min={1}
                                        max={10}
                                        style={{ color: DARK_BLUE }}
                                        onChange={(e, n) => {
                                            let thisPain = PainLocationPointsAndData.filter(x => x.ID == value.ID)[0];
                                            let allPains = PainLocationPointsAndData;

                                            thisPain.scale = n;

                                            allPains[index] = thisPain;
                                            setPainLocationPointsAndData(prevx => allPains);
                                            setUseLess(Math.random);
                                        }}
                                    />
                                    <p style={{ color: DARK_BLUE }}>
                                        What is the quality of your pain?
                                    </p>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {
                                            props.CurrentQuestion?.AnswerChoice[0]?.scaleString?.split(",").map((checkboxName, indexForCheckBox) => {
                                                return (
                                                    <FormControlLabel
                                                        key={indexForCheckBox}
                                                        control={
                                                            <Checkbox
                                                                checked={PainLocationPointsAndData.filter(x => x.ID == value.ID)[0].checkBoxes.includes(checkboxName)}
                                                                onClick={() => {
                                                                    let thisPain = PainLocationPointsAndData.filter(x => x.ID == value.ID)[0];
                                                                    let allPains = PainLocationPointsAndData;

                                                                    if (allPains.filter(x => x.ID == value.ID)[0].checkBoxes.includes(checkboxName)) {


                                                                        thisPain.checkBoxes = thisPain.checkBoxes.filter(x => x != checkboxName);

                                                                        allPains[index] = thisPain;
                                                                        setPainLocationPointsAndData(prevx => allPains);
                                                                        setUseLess(Math.random);
                                                                    } else {


                                                                        thisPain.checkBoxes = [...thisPain.checkBoxes, checkboxName];

                                                                        allPains[index] = thisPain;
                                                                        setPainLocationPointsAndData(prevx => allPains);
                                                                        setUseLess(Math.random);
                                                                    }
                                                                }}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={checkboxName}
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <p style={{ textAlign: "center", color: "red" }}>
                    {BodyPainError}
                </p>
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    {
                        IsBodyPainLoading ? (
                            <CircularProgress />
                        ) : <FooterPart />
                    }
                </div>
            </div>
        </>
    );


    // the one with yesTextEntry
    if (props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId == 21) return (
        <>
            <div className="mb-5" >
                <HeaderPart />
                <FormControl component="fieldset">
                    <FormLabel color="primary" component="legend">Select</FormLabel>
                    <RadioGroup color="primary" aria-label="gender" name="gender1"  >
                        <FormControlLabel
                            color="primary"
                            value={true}
                            control={<Radio color="primary" />}
                            label={"Yes"}
                            checked={YesTextEntryRadio === true}
                            onClick={() => setYesTextEntryRadio(true)}
                        />
                        {
                            YesTextEntryRadio ? (
                                <>
                                    <TextField
                                        variant="standard"
                                        style={{ width: "300px" }}
                                        label={"Enter your comments"}
                                        value={YesText}
                                        onChange={(e) => setYesText(e.target.value)}
                                        className="mb-3"
                                    />
                                    <br />

                                </>
                            ) : null
                        }
                        <FormControlLabel
                            color="primary"
                            value={false}
                            control={<Radio color="primary" />}
                            label={"No"}
                            checked={YesTextEntryRadio === false}
                            onClick={() => setYesTextEntryRadio(false)}
                        />
                    </RadioGroup>
                </FormControl>
                <p style={{ textAlign: "center", color: "red" }}>
                    {YesTextEntryError}
                </p>
                <div className="d-flex justify-content-end" style={{ width: "100%" }} >
                    <FooterPart />
                </div>
            </div>
        </>
    );


    return (
        <>
            <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <HeaderPart />
                {/* <h3 style={{ color: "grey" }}>Question Loading failed for Q:{props.CurrentQuestion?.AnswerTypeLkp?.answerTypeId}</h3> */}
                <h3 style={{ color: "grey" }}>Survey could not be loaded successfully.</h3>
                <FooterPart />
            </div>
        </>
    );
};

SurveryDialogContent.propTypes = {
    IsSurveyLoading: PropTypes.bool.isRequired,
    SelectedSurvey: PropTypes.object.isRequired,
    CurrentQuestionIndex: PropTypes.number.isRequired,
    TotalQuestions: PropTypes.number.isRequired,
    ErrorMsg: PropTypes.string.isRequired,
    CurrentQuestion: PropTypes.object.isRequired,
    Finished: PropTypes.bool.isRequired,
    Last: PropTypes.bool.isRequired,
    moveToNextQuestion: PropTypes.func.isRequired,
};

export default SurveryDialogContent;
