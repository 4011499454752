import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Clock from "../../assests/icons/clock.svg";
import List from "../../assests/icons/list.svg";
import Repeat from "../../assests/icons/repeat.svg";
import arrowLeft from "../../assests/icons/arrowLeft.svg";
import playBlue from "../../assests/icons/playBlue.svg";
import pause from "../../assests/icons/pause.svg";
import prevIconHep from "../../assests/icons/prevIconHep.png";
import nextIconHep from "../../assests/icons/nextIconHep.png";
import sample from "../../assests/images/sample.png";
import { useMobTabDeskDetctor } from "../../helpers/useMobTabDesktop";
import { Button, TextareaAutosize } from "@mui/material";
import { DARK_BLUE } from "../../constants";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions";
import parse from "html-react-parser";

const HepFullScreenNew = props => {

    const { IsMob, IsTab, IsWeb } = useMobTabDeskDetctor();
    const dispatch = useDispatch();
    const videoRef = useRef();

    const [CurrentVideo, setCurrentVideo] = useState(0);
    const [CurrentRepeat, setCurrentRepeat] = useState(1);
    const [CurrentSet, setCurrentSet] = useState(1);
    const [IsPlaying, setIsPlaying] = useState(false);
    const [IsCompleted, setIsCompleted] = useState(false);


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10000000,
            height: "100vh",
            width: "100%",
            overflow: "hidden",
            overflowY: !IsWeb ? "scroll" : ""
        }} >
            <div className="row" >
                <div className="col-lg-12 col-md-12 col-sm-12 py-3" style={{ borderRight: "15px solid #eaebee", minHeight: IsWeb ? "100vh" : "" }} >
                    <div className="card-body" >

                        <div className="tabsMenu py-2" style={{ display: IsWeb ? "flex" : "block", justifyContent: "space-between", alignItems: "center" }} >
                            <h5 style={{ color: "#2a2a2a", fontSize: "20px" }} >
                                <img
                                    src={arrowLeft}
                                    alt="icon"
                                    className="mr-1"
                                    style={{ height: "25px", cursor: "pointer" }}
                                    onClick={() => props.backToMenu(IsCompleted)}
                                />
                                {CurrentVideo + 1}. {props.data[CurrentVideo].videoName}
                            </h5>

                            <p className="d-flex align-items-center" style={{ color: "#3a3a3a", fontSize: "14px", marginTop: "20px" }} >
                                <img
                                    src={Repeat}
                                    alt="icon"
                                    className="mr-2"
                                    style={{ height: "15px" }}
                                />
                                {props.data[CurrentVideo].TreatmentVideoRel?.repeatNumb || props.data[CurrentVideo].repeatNumb} reps
                                <img
                                    src={List}
                                    alt="icon"
                                    className="ml-3 mr-2"
                                    style={{ height: "15px" }}
                                />
                                {props.data[CurrentVideo].TreatmentVideoRel?.videoRepeat || props.data[CurrentVideo].videoRepeat} set
                                <img
                                    src={Clock}
                                    alt="icon"
                                    className="ml-3 mr-2"
                                    style={{ height: "15px" }}
                                />
                                {props.data[CurrentVideo].TreatmentVideoRel?.holdingRepeat || props.data[CurrentVideo].holdingRepeat}s hold
                            </p>

                        </div>

                        {
                            IsCompleted ? (
                                <>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <h4 style={{ textAlign: "center", marginTop: "100px" }}>Well done! you have completed the HEP.</h4>
                                        <Button onClick={() => props.backToMenu(IsCompleted)} style={{ marginTop: "50px" }} variant="outlined">
                                            Go Back
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ height: "400px", width: "100%", }}
                                        className="d-flex justify-content-center align-items-center mb-4"
                                    >
                                        {/* <div
                                style={{
                                    height: "100%",
                                    width: IsWeb ? "100%" : "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    backgroundImage: `url(${sample})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}>
                                <img alt="icon"
                                    style={{ height: "50px", cursor: "pointer" }}
                                    src={playBlue}
                                // onClick={() => setCurrentVideo(index)}
                                />
                            </div> */}
                                        {/* <img alt="icon" src={playBlue} /> */}
                                        <video autoPlay={true}
                                            onPlay={() => {
                                                dispatch(Actions.UpdateTreatmentCheckSum(props.treatmentId));
                                                setIsPlaying(true);
                                            }}
                                            onPause={() => {
                                                setIsPlaying(false);
                                            }}
                                            ref={videoRef}
                                            style={{
                                                maxWidth: "100%",
                                                cursor: "pointer",
                                                height: "90%"
                                            }}
                                            id="videoplayer"
                                            playsinline=""
                                            webkit-playsinline=""
                                            poster={`https://dfi4wfwfroz6l.cloudfront.net/${props.data[CurrentVideo].image}`}
                                            controls
                                            src={
                                                props.data[CurrentVideo].isInstalled == 1 ?
                                                    `https://d3i9v5bkcvmkzb.cloudfront.net/${props.data[CurrentVideo].filename}` :
                                                    `https://d1rctz31urrh7u.cloudfront.net/${props.data[CurrentVideo].filename.replace(".mp4", "_1024.mp4")}`
                                            }
                                            onEnded={() => {
                                                if (parseInt(props.data[CurrentVideo].TreatmentVideoRel?.videoRepeat || props.data[CurrentVideo].videoRepeat) > CurrentSet) {
                                                    setCurrentSet(CurrentSet + 1);
                                                    setTimeout(() => {
                                                        videoRef.current.play();
                                                    }, 100);
                                                    const totalNum = props.data.length;
                                                    const currentNum = CurrentVideo + 1;
                                                    const percent = ((currentNum / totalNum) * 100).toFixed(2).toString();

                                                    dispatch(Actions.submitLogProgressData(percent, props.treatmentId));
                                                    return;
                                                }
                                                if (CurrentVideo === props.data.length - 1) {
                                                    if (CurrentRepeat == props.repeat) {
                                                        setIsCompleted(true);
                                                        dispatch(Actions.submitLogProgressData("100.00", props.treatmentId));
                                                        return;
                                                    }
                                                    setCurrentRepeat(prevx => prevx + 1);
                                                    setCurrentSet(1);
                                                    setTimeout(() => {
                                                        videoRef.current.play();
                                                    }, 100);

                                                }
                                                const totalNum2 = props.data.length;
                                                const currentNum2 = CurrentVideo + 1;
                                                const percent2 = ((currentNum2 / totalNum2) * 100).toFixed(2).toString();

                                                dispatch(Actions.submitLogProgressData(percent2, props.treatmentId));
                                                setCurrentSet(1);
                                                setCurrentVideo(prevx => prevx !== (props.data.length - 1) ? prevx + 1 : 0);
                                            }}
                                        />
                                    </div>

                                    <div className="container" >

                                        <div className="mt-4"
                                            style={{ width: "100%", display: IsMob ? "block" : "flex", justifyContent: "space-between", alignItems: "center" }} >
                                            <p style={{ fontSize: "14px" }} >

                                            </p>
                                            <div className="icons" >
                                                <img alt="prev"
                                                    className="mb-2"
                                                    style={{ height: "35px", cursor: "pointer" }}
                                                    src={prevIconHep}
                                                    onClick={() => setCurrentVideo(prevx => prevx !== 0 ? prevx - 1 : 0)}
                                                />
                                                <img alt="play"
                                                    className="mb-2 mx-4"
                                                    style={{ height: "40px", cursor: "pointer" }}
                                                    src={IsPlaying ? pause : playBlue}
                                                    onClick={() => {
                                                        if (IsPlaying) {
                                                            videoRef.current.pause();
                                                            setIsPlaying(false);
                                                        } else {
                                                            videoRef.current.play();
                                                            setIsPlaying(true);
                                                        }
                                                    }}
                                                />
                                                <img alt="next"
                                                    className="mb-2"
                                                    style={{ height: "35px", cursor: "pointer" }}
                                                    src={nextIconHep}
                                                    onClick={() => setCurrentVideo(prevx => prevx !== (props.data.length - 1) ? prevx + 1 : props.data.length - 1)}
                                                />
                                            </div>
                                            <p style={{ fontSize: "14px" }} >
                                                <span style={{ fontSize: "14px", fontWeight: "500" }} >Video:</span> {CurrentVideo + 1}/{props.data.length}
                                            </p>
                                        </div>

                                        <hr className="mt-2 mb-4" style={{ backgroundColor: "#e4ebf3" }} />


                                        {
                                            props.data[CurrentVideo].TreatmentVideoRel?.comment || props.data[CurrentVideo].comment ? (
                                                <>
                                                    <h5 className="my-2" style={{ color: "#333333", fontSize: "18px" }} >
                                                        PT's instructions
                                                    </h5>
                                                    <p className="" style={{ fontSize: "14px", width: "75%" }} >
                                                        {parse(props.data[CurrentVideo].TreatmentVideoRel?.comment.replaceAll("\n", "<br/>") || props.data[CurrentVideo].comment).replaceAll("\n", "<br/>") || ""}
                                                    </p>
                                                </>
                                            ) : null
                                        }
                                        {
                                            props.data[CurrentVideo].TreatmentVideoRel?.voicefileurl?.signedUrl || props.data[CurrentVideo].voicefile ? (
                                                <>
                                                    <h5 className="my-3" style={{ color: "#333333", fontSize: "18px" }} >
                                                        Voice note
                                                    </h5>
                                                    <audio controls>
                                                        <source
                                                            src={props.data[CurrentVideo].TreatmentVideoRel?.voicefileurl?.signedUrl || props.data[CurrentVideo].voicefile?.signedUrl}

                                                        />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-12 py-5" >
                    <div className="container" >
                        <h6 className="mt-2" style={{ color: "#2a2a2a", fontSize: "18px" }} >
                            having any isuue?
                        </h6>
                        <p style={{ color: "#2a2a2a", fontSize: "14px" }} >
                            If you are having any trouble doing this exercise please leave message
                        </p>
                        <TextareaAutosize
                            // aria-label="maximum height"
                            minRows={3}
                            placeholder="Minimum 2 rows"
                            style={{ width: "96%", padding: "5px", border: "1px solid #e4ebf3", height: "200px" }}
                        />
                        <div className="text-right mt-3 mr-3" >
                            <Button
                                variant="contained"
                                style={{ background: DARK_BLUE, color: "white", marginLeft: "10px", textTransform: "capitalize" }}
                                size="small"
                            >
                                send
                            </Button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

HepFullScreenNew.propTypes = {};

export default HepFullScreenNew;