import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getToken } from ".";
import { getUserID, getParams, getClinicGroupItemId, getQueryParamValue} from ".";
import { toast } from "react-toastify";
const urlParams = new URLSearchParams(window.location.search);
const isIframe = urlParams.get("isIframe");

// GetCompanyEmployees
// GetCompanySites
// GetCompanyHospitals

export const GetCompanyHospitalsList = (page = 0, searchText = "") => async (dispatch, getState) => {
    try {
            

        const res = await axios.get(`${getBaseURL()}/CompanyHospitals/${getClinicGroupItemId()}${getParams(getState)}&userId=${getUserID(getState)}&name=${searchText}&page=${page}`,);
        // const doctorIdParam = '4277';
        // const token = 'AxHgOCvtWh9bgdTXR_mVz-ixORzuTeNLQbgThUT3xNyxUrwAps4ma9XB4VjCFpmM';
        // const res = await axios.get(`${getBaseURL()}/CompanyHospitals?doctorIdParam=${doctorIdParam}&token=${token}&page=${page}`)

        if (res.data.error) {
            return res.data.msg;
        }

        if (!Array.isArray(res.data)) return "Loading failed!";

        dispatch(ReducerActions.setCompanyList(res.data));
        dispatch(ReducerActions.setManageClientsList(res.data));
        return res.data;

    } catch (error) {
        console.log(error);
    }
};

export const GetCompanySitesList = (page = 0, clinicId = 0, searchText = "") => async (dispatch, getState) => {
    try {
            

        const res = await axios.get(`${getBaseURL()}/CompanySites/${clinicId}${getParams(getState)}&userId=${getUserID(getState)}&name=${searchText}&page=${page}`,);
        // const doctorIdParam = '4277';
        // const token = 'AxHgOCvtWh9bgdTXR_mVz-ixORzuTeNLQbgThUT3xNyxUrwAps4ma9XB4VjCFpmM';
        // const res = await axios.get(`${getBaseURL()}/CompanySites/${clinicId}?doctorIdParam=${doctorIdParam}&token=${token}&page=${page}`)


        if (res.data.error) {
            return res.data.msg;
        }

        if (!Array.isArray(res.data)) return "Loading failed!";

        dispatch(ReducerActions.setSiteList(res.data));
        dispatch(ReducerActions.setManageClientsList(res.data));

        return res.data;

    } catch (error) {
        console.log(error);
    }
};

export const GetCompanyEmployeesList = (page = 0, clinicId = 0, searchText = "") => async (dispatch, getState) => {
    try {
            
        const res = await axios.get(`${getBaseURL()}/CompanyEmployees/${clinicId}${getParams(getState)}&userId=${getUserID(getState)}&name=${searchText}&page=${page}`,);
        // const doctorIdParam = '4277';
        // const token = 'AxHgOCvtWh9bgdTXR_mVz-ixORzuTeNLQbgThUT3xNyxUrwAps4ma9XB4VjCFpmM';
        // const res = await axios.get(`${getBaseURL()}/CompanyEmployees/${clinicId}?doctorIdParam=${doctorIdParam}&token=${token}&page=${page}`)


        if (res.data.error) {
            return res.data.msg;
        }

        if (!Array.isArray(res.data)) return "Loading failed!";

        dispatch(ReducerActions.setManageClientsList(res.data));

        return res.data;

    } catch (error) {
        console.log(error);
    }
};

export const AdminRegisterEmployeeByEmail = (obj) => async (dispatch, getState) => {
    try {
        const body = {...obj, token: getQueryParamValue("accessCode"), adminDocterId: getQueryParamValue("adminDocterId")};
        const res = await axios.post(`${getBaseURL()}/AdminRegisterEmployeeByEmail${getParams(getState)}`, body);
        if (res.data.status != 1) {
            toast.error(res.data.msg);
        } else {
            toast.success(res.data.msg);
        }

        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const EmployeeSelfRegister = (obj) => async (dispatch, getState) => {
    try {
        const body = {...obj, token: getQueryParamValue("accessCode"), adminDocterId: getQueryParamValue("adminDocterId")};
        const res = await axios.post(`${getBaseURL()}/EmployeeSelfRegister${getParams(getState)}`, body);
        if (res.data.status != 1) {
            toast.error(res.data.msg);
        } else {
            toast.success(res.data.msg);
        }

        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const CreateCompany = (obj) => async (dispatch, getState) => {
    try {
        const body = {...obj, clinicGroupItemId: getClinicGroupItemId()};
        const res = await axios.post(`${getBaseURL()}/CreateCompany${getParams(getState)}`, body);
        if (res.data.status != 1) {
            toast.error(res.data.msg);
        } else {
            toast.success("Company added successfully!");
        }

        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const CreateSite = (obj) => async (dispatch, getState) => {
    try {
        const body = obj;
        const res = await axios.post(`${getBaseURL()}/CreateCompanySite${getParams(getState)}`, body);
        if (res.data.status != 1) {
            toast.error(res.data.msg);
        } else {
            toast.success("Site added successfully!");
        }
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const CreateEmployee = (obj) => async (dispatch, getState) => {
    try {
        const body = {...obj, adminDocterId: getUserID(getState)};
        const res = await axios.post(`${getBaseURL()}/CreateSiteEmployee${getParams(getState)}`, body);
        if (res.data.status != 1) {
            toast.error(res.data.msg);
        } else {
            toast.success("Employee added successfully!");
        }
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const DeleteCompany = (clinicId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/DeleteCompany/${clinicId}${getParams(getState)}&userId=${getUserID(getState)}`);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const DeleteCompanySite = (clinicId) => async (dispatch, getState) => {
    try {

        const res = await axios.get(`${getBaseURL()}/DeleteCompanySite/${clinicId}${getParams(getState)}&userId=${getUserID(getState)}`);
        return res.data;

    } catch (error) {
        console.log(error);
    }
};

export const DeleteCompanySiteEmployee = (empId) => async (dispatch, getState) => {
    try {
            
        const res = await axios.get(`${getBaseURL()}/DeleteCompanySiteEmployee/${empId}${getParams(getState)}&userId=${getUserID(getState)}`);
        return res.data;

    } catch (error) {
        console.log(error);
    }
};
