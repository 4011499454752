import { createSlice } from "@reduxjs/toolkit";

export const DocumentSlice = createSlice({
    name: "Document",
    initialState: {
        DocumentData: [],
        OnlyFolders: []
    },
    reducers: {
        setDocumentData: (state, action) => {
            state.DocumentData = action.payload;

        },
        setOnlyFolders: (state, action) => {
            state.OnlyFolders = action.payload;

        },

    },
});

export const { setDocumentData, setOnlyFolders } = DocumentSlice.actions;


export const DocumentReducer = DocumentSlice.reducer;