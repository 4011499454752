import { createSlice } from "@reduxjs/toolkit";

export const FitbitSlice = createSlice({
    name: "Fitbit",
    initialState: {
        SleepData: [],
        ActivityDataForTable: [],
        ActivityData: [],
        FoodData: [],
        WeightData: [],
        HRVData: [],
        NeedToConnect: true,
        IsLoadMoreVisible: true,
    },
    reducers: {
        cleanUpActivityData: (state, action) => {
            state.ActivityData = [];
            state.ActivityDataForTable = [];
        },
        setSleepData: (state, action) => {
            state.SleepData = action.payload;
        },
        setActivityDataForTable: (state, action) => {
            state.ActivityDataForTable = [...state.ActivityDataForTable, ...action.payload];
        },
        setActivityData: (state, action) => {
            state.ActivityData = [...state.ActivityData, ...action.payload];
        },
        setFoodData: (state, action) => {
            state.FoodData = action.payload;
        },
        setWeightData: (state, action) => {
            state.WeightData = action.payload;
        },
        setHRVData: (state, action) => {
            state.HRVData = action.payload;
        },
        setNeedToConnect: (state, action) => {
            state.NeedToConnect = action.payload;
        },
        setIsLoadMoreVisible: (state, action) => {
            state.IsLoadMoreVisible = action.payload;
        }

    },
});

export const {
    setActivityDataForTable,
    setActivityData,
    setFoodData,
    setSleepData,
    setWeightData,
    setNeedToConnect,
    setHRVData,
    setIsLoadMoreVisible,
    cleanUpActivityData
} = FitbitSlice.actions;


export const FitbitReducer = FitbitSlice.reducer;