import axios from "axios";
import { GetPatientsProvidersList, getUserID } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getToken, getParams } from "./uiContent.actions";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get("userId");
const doctorAdminId = urlParams.get("doctorAdminId");

export const GetAllProvidersForChat = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/PatientChatDetail/${userId || getUserID(getState)}/0${getParams(getState)}&versionId=1634547837`);

        dispatch(ReducerActions.setMessages(res.data || []));


    } catch (error) {
        console.log(error.response);
    }
};

export const SendMessages = (TypeId, message,) => async (dispatch, getState) => {


    const AllMsgs = getState().Msg?.Messages?.ChatMsg || [];

    const OnlyProviders = AllMsgs.filter(x => x.isDoctorSend > 0);
    let lastProvider = OnlyProviders[OnlyProviders.length - 1];

    if(!lastProvider) {
        const PL = await dispatch(GetPatientsProvidersList());
        lastProvider = PL[PL.length - 1];
    }

    const body = [{

        "adminDocterId": doctorAdminId || lastProvider.adminDocterId ,
        "userId": userId || getUserID(getState),
        "isDoctorSend": doctorAdminId ? "1" : "0",
        "fileTypeId": TypeId,
        "chatTime": new Date().toISOString(),
        "msgValue": message,
        "versionId": "0",
        "UUID": uuidv4()

    }];

    try {

        const res = await axios.post(`${getBaseURL()}/ChatMsgCareGiver/${userId || getUserID(getState)}/${userId || getUserID(getState)}/${userId || getUserID(getState)}/${userId || getUserID(getState)}${getParams(getState)}`, body);
        if (res.data) return true;
        return false;

    } catch (error) {

        console.log(error.response);
        return false;
    }
};



export const UploadFileFromMessages = (fileName, fileTypeId) => async (dispatch, getState) => {

    try {

        const AllMsgs = getState().Msg?.Messages?.ChatMsg || [];

        const OnlyProviders = AllMsgs.filter(x => x.isDoctorSend == 1);

        let lastProvider = OnlyProviders[OnlyProviders.length - 1];

        if(!lastProvider) {
            const PL = await dispatch(GetPatientsProvidersList());
            lastProvider = PL[PL.length - 1];
        }

        const body = new FormData();

        body.append("file", fileName);

        const chatData = JSON.stringify({
            "adminDocterId": doctorAdminId || lastProvider?.adminDocterId ,
            "userId": userId || getUserID(getState),
            "fileTypeId": fileTypeId,
            "chatTime": new Date().toISOString()
        });

        body.append("chatData", chatData);

        const res = await axios.post(`${getBaseURL()}/UploadFileInChat/${userId || getUserID(getState)}/health001/${getToken(getState)}${getParams(getState)}`, body);

        if (res.data.status == 1) return true;
        // if (res.data?.result?.ActualImageURL) return res.data?.result?.ActualImageURL

        return null;

    } catch (error) {

        console.log(error.response);
        toast.error(error?.response?.data?.message || "Something went wrong");
        return false;
    }
};


export const getNotes = () => async (dispatch, getState) => {

    try {

        const res = await axios.get(`${getBaseURL()}/getPatientNotes/${userId || getUserID(getState)}/${userId || getUserID(getState)}${getParams(getState)}`);


        dispatch(ReducerActions.setNotes(res.data));

    } catch (error) {

        console.log(error.response);
        return false;
    }
};
