import { createSlice } from "@reduxjs/toolkit";

export const PatientListSlice = createSlice({
    name: "PatientList",
    initialState: {
        PatientListData: [],
        TotalPatients: 0,
        pageCount:0,
        AllCompanies: [],
        AllThePatients: [],
        InsuranceCompanies: [],
        DxCodesData: [],
    },
    reducers: {
        setPatientListData: (state, action) => {
            state.PatientListData = action.payload.patients;
            state.TotalPatients = action.payload.total;
            state.pageCount = action.payload.pageCount;
        },
        setEnrolledPatientListData: (state, action) => {
            const patientId = action.payload.id;
            const patientList = state.PatientListData.filter(
                (patient) => patient.userId != patientId
            );
            state.PatientListData = patientList;
        },
        setAllCompanies: (state, action) => {
            state.AllCompanies = action.payload;
        },
        setAllThePatients: (state, action) => {
            state.AllThePatients = action.payload;
        },
        setInsuranceCompaniesData: (state, action) => {
            state.InsuranceCompanies = action.payload;
        },
        setDxCodesData: (state, action) => {
            state.DxCodesData = action.payload;
        },
    },
});

export const {
    setAllCompanies,
    setPatientListData,
    setEnrolledPatientListData,
    setAllThePatients,
    setInsuranceCompaniesData,
    setDxCodesData,
} = PatientListSlice.actions;

export const PatientListReducer = PatientListSlice.reducer;
