import React from "react";
// import { useNavigate } from "react-router-dom";

const ProgramInfo = ({ CurrentProgram }) => {


    // const navigate = useNavigate();


    return (
        <div className='row' >
            <div  className="col-lg-12 col-md-12 col-sm-12 mb-3" >
                <div className="card border-0" style={{ borderRadius: 0 }}>
                    <div className="d-flex justify-content-end mr-3 mt-3" >

                    </div>
                    <div className="row g-0 p-3 mb-3">
                        <div className="col-md-5"
                            style={{ overflow: "hidden",display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <img src={CurrentProgram.imgFile ? `https://dfi4wfwfroz6l.cloudfront.net/${CurrentProgram.imgFile}`: "/RTM.png"} className="img-fluid" style={{ width: "80%" }} alt="..." />
                            {/* <div style={{
                                height: "100%",
                                width: "100%",
                                backgroundImage: `url(${CurrentProgram.imgFile ? `https://dfi4wfwfroz6l.cloudfront.net/${CurrentProgram.imgFile}`: "/RTM.png"})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }} /> */}
                        </div>
                        <div className="col-md-7 pl-0">
                            <div className="">

                                <div className="d-flex justify-content-between" >
                                    <h4 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px" }} >
                                        {CurrentProgram.title}
                                    </h4>
                                </div>
                                
                                <p style={{ fontSize: "13px", lineheight: "1.74" }} >
                                    {CurrentProgram.description?.slice(0, 200)}{CurrentProgram.description.length > 200 ? "..." : ""}
                                </p>
                                {
                                    CurrentProgram.benefits ? (
                                        <>
                                            <p style={{ fontWeight: "500", fontSize: "14px" }} >
                                                Benefits
                                            </p>
                                            <ul className="pl-3" style={{ fontWeight: "500", listStyleType: "circle", fontSize: "13px" }} >

                                                {CurrentProgram.benefits.split("\/").map((CurrentProgram, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {CurrentProgram}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    ) : null
                                }

                                <p style={{ marginBottom: "0px" }}>Program Type {CurrentProgram.programType === "RTM" && "(RTM)"}</p>
                                <span style={{ fontWeight: "500", fontSize: "14px", color: "gray" }} >
                                    <span style={{ color:"#0695db" }}>{CurrentProgram.programStyle}</span> . {CurrentProgram.programDurationNumb} {CurrentProgram.programDurationType}{CurrentProgram.programDurationNumb > 1 && "s"}
                                </span>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramInfo;