import { createSlice } from "@reduxjs/toolkit";

export const UiContentSlice = createSlice({
    name: "UiContent",
    initialState: {
        isSideBarOpen: sessionStorage.getItem("@IsSideBarClose") ? false : true,
        ShowMessagesOnly: sessionStorage.getItem("@ShowMessagesOnly") ? true : false,

    },
    reducers: {
        setShowMessagesOnly: (state) => {
            state.ShowMessagesOnly = true;
            sessionStorage.setItem("@ShowMessagesOnly","true");
        },
        openCloseSideBar: (state) => {
            if (state.isSideBarOpen) {
                sessionStorage.setItem("@IsSideBarClose", "true");
            } else {
                sessionStorage.removeItem("@IsSideBarClose");
            }
            state.isSideBarOpen = !state.isSideBarOpen;
        },
    },
});

export const { openCloseSideBar, setShowMessagesOnly } = UiContentSlice.actions;


export const UiContentReducer = UiContentSlice.reducer;