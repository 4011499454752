import { configureStore } from "@reduxjs/toolkit";
import { MessagesReducer, SurveyAndMaterialReducer, UiContentReducer, UserReducer, HEPReducer, DocumentReducer, UpcomingVisitReducer, HealthReducer, ManageClientsReducer, RTMReducer } from "./reducers";
import { FitbitReducer } from "./reducers/fitbit.reducer";
import { ProgramsReducer } from "./reducers/programer.reducer";
import { PatientListReducer } from "./reducers/patientlist.reducer";

export default configureStore({
    reducer: {
        User: UserReducer,
        UI: UiContentReducer,
        HEP: HEPReducer,
        Msg: MessagesReducer,
        SurveyAndMaterial: SurveyAndMaterialReducer,
        Document: DocumentReducer,
        Visits: UpcomingVisitReducer,
        HealthData: HealthReducer,
        Programs: ProgramsReducer,
        Fitbit: FitbitReducer,
        ManageClients: ManageClientsReducer,
        RTMReducer: RTMReducer,
        PatientList: PatientListReducer,
    },
});
