import React from "react";
import sample from "../../assests/images/sample.png";
import cross from "../../assests/icons/cross.svg";
import carrot from "../../assests/icons/carrot.svg";




const Tasks = props => {
    return (
        <div className="row pb-4" style={{ borderBottom: "1px solid #e4ebf3" }} >
            {
                [1, 2, 3, 4].map((value, index) => {
                    return (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-12 mt-4 px-2" >
                            <div className="card" style={{ borderColor: "#e4ebf3",borderRadius:"8px" }} >
                                <img className="card-img-top" style={{ height: "110px" }} src={sample} alt="Card img cap" />
                                <div className="card-body py-2">
                                    <p className="mb-1" style={{ fontSize: "14px", fontWeight: "500" }}>
                                        Drink 8 glass of water
                                    </p>
                                    <p className="mb-0" style={{ color: "#333333", fontSize: "14px", opacity: "0.7" }} >
                                        Apr 19,2021
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between" >
                                        <span style={{ color: "#333333", fontSize: "14px" }} >
                                            Nutrition
                                        </span>
                                        <div style={{ cursor: "pointer" }} >
                                            <img style={{height:"18px",marginRight:"10px"}} alt="icon" src={cross} />
                                            <span className="mx-1" style={{ fontSize: "14px", color: "#333333" }} >Not Done</span>
                                            <img alt="icon" src={carrot} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>

    );
};

Tasks.propTypes = {

};

export default Tasks;
