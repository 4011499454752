import React, { useEffect, useState } from "react";
import { DARK_BLUE } from "../../constants";
import moment from "moment";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { getMeetingServerURL } from "../../helpers/getBaseURL";

const DashboardTelehealth = ({
    SelectedModule,
    SelectedModuleData,
}) => {

    const [UselessState, setUselessState] = useState(0);


    useEffect(() => {
        let myInterval = setInterval(() => {
            setUselessState(prevx => prevx + 1);
        }, 20000);

        return () => {
            clearInterval(myInterval);
        };
    }, []);

    console.log({
        SelectedModule,
        SelectedModuleData
    });

    return (
        <div className="col-lg-7 col-md-12 mb-2" >
            <div className="card" style={ { border: 0 } } >
                <div className="d-flex align-items-center justify-content-between py-3 px-3"
                    style={ { fontSize: "14px", fontWeight: "500", borderBottom: "1px solid #e4ebf3" } }
                >
                    <span style={ { color: DARK_BLUE, fontSize: "16px", fontWeight: "500" } } >
                        { SelectedModule.moduleTitle }
                    </span>
                    <div className="d-flex align-items-center" >

                    </div>

                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Meeting Room
                            </TableCell>
                            <TableCell>
                                Date & Time
                            </TableCell>
                            <TableCell>
                                Provider
                            </TableCell>
                            <TableCell>
                                Provide Phone
                            </TableCell>
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            SelectedModuleData.map((value, index) => {

                                let disableStartButton = moment().isBefore(moment(value.startTime, "YYYY-MM-DD HH:mm Z").subtract(5, "minute"));
                                let isAfterTime = moment().isAfter(moment(value.startTime, "YYYY-MM-DD HH:mm Z").add(value.interval_min
                                    , "minutes"));

                                return (
                                    <TableRow key={ index } >
                                        <TableCell style={ { color: "grey" } }>
                                            { value.roomNumb || "" }
                                        </TableCell>
                                        <TableCell style={ { color: "grey" } }>
                                            { moment.utc(value.startTime, "YYYY-MM-DD HH:mm:aa Z").local().format("MMMM DD, YYYY  hh:mm A") }
                                        </TableCell>
                                        <TableCell style={ { color: "grey" } }>
                                            { value.provider_firstName || "" } { value.provider_lastName }
                                        </TableCell>
                                        <TableCell style={ { color: "grey" } }>
                                            { value.phoneNo }
                                        </TableCell>
                                        <TableCell style={ { color: "grey" } }>
                                            <Button
                                                onClick={ () => {
                                                    window.open(`${ getMeetingServerURL() }/room/${ value.roomNumb }`, "_blank");
                                                } }
                                                variant="contained"
                                                disabled={ disableStartButton  || isAfterTime}

                                            >
                                                {
                                                    isAfterTime  ? "Expired" : "Start"
                                                }
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </div>

        </div>
    );
};

export default DashboardTelehealth;