import React, { useState, useEffect } from "react";
import { DARK_BLUE } from "../../constants";
import moment from "moment";
import SurveryDialogContent from "../surveyAndMaterial/SurveryDialogContent";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions";
import { CircularProgress } from "@mui/material";
import { getOnlyBaseURL } from "../../helpers/getBaseURL";

const DashboardSurvey = ({ SelectedModule, SelectedModuleData }) => {
    const dispatch = useDispatch();

    // const [Data, setData] = useState(null);
    // const [IsSpanish, setIsSpanish] = useState(false);
    // const [ShowSwitch, setShowSwitch] = useState(false);
    // const [IsFirstTimePageLoad, setIsFirstTimePageLoad] = useState(true);
    const [IsLoading, setIsLoading] = useState(true);
    const [ErrorMsg, setErrorMsg] = useState("");

    // const [Index, setIndex] = useState(1);
    const [SelectedSurvey, setSelectedSurvey] = useState({});
    const [CurrentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [AllQuestions, setAllQuestions] = useState([]);
    const [TotalQuestions, setTotalQuestions] = useState(0);

    useEffect(() => {
        getData();
    }, []);

    async function moveToNextQuestion(answer) {
        try {
            setCurrentQuestionIndex(CurrentQuestionIndex + 1);
        } catch (error) {
            console.log(error);
        }
    }
    async function moveToPreviousQuestion(answer) {
        try {
            setCurrentQuestionIndex(CurrentQuestionIndex - 1);
        } catch (error) {
            console.log(error);
        }
    }

    async function submitSurvey(Answers) {
        setCurrentQuestionIndex(CurrentQuestionIndex + 1);
        const res = await dispatch(
            Actions.SubmitSurvey(Answers, {}, AllQuestions)
        );
        if (res) window.location.reload();
    }

    async function getData() {
        try {
            setIsLoading(true);
            setErrorMsg("");

            setErrorMsg("");
            setSelectedSurvey({});
            setCurrentQuestionIndex(0);
            setAllQuestions([]);
            setTotalQuestions(0);

            const surveyQuestions = await dispatch(
                Actions.GetSurveyQuestions(
                    SelectedModuleData[0].sypLogTypeId,
                    false
                )
            );

            if (surveyQuestions.length == 0) {
                setErrorMsg("No survey found.");
                setIsLoading(false);
                return;
            }

            setAllQuestions(surveyQuestions);
            setTotalQuestions(surveyQuestions.length);
            setIsLoading(false);
            return;
        } catch (error) {
            setIsLoading(false);
            setErrorMsg("Survey could not be loaded successfully.");
        }
    }

    if (IsLoading)
        return (
            <div
                style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="col-lg-7 col-md-12 mb-2"
            >
                <CircularProgress />
            </div>
        );

    if (ErrorMsg)
        return (
            <div
                style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="col-lg-7 col-md-12 mb-2"
            >
                <h4 style={{ color: "grey" }}>{ErrorMsg}</h4>
            </div>
        );

    //https://www.myhomept.co/PT2/httpdocs/libs/opensurveyfile.php?filename

    if (moment().isBefore(moment(SelectedModule.moduleStartDate * 1000)))
        return (
            // if (true) return (
            <div
                className="col-lg-7 col-md-12 mb-2"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h5 style={{ color: "grey", padding: "20px" }}>
                    You can answer this survey on{" "}
                    {moment(SelectedModule.moduleStartDate * 1000).format(
                        "MM-DD-YYYY"
                    )}{" "}
                </h5>
                <iframe
                    src={
                        getOnlyBaseURL() +
                        "//libs/opensurveyfile.php?filename=" +
                        SelectedModuleData[0].filename_url
                    }
                    title="Iframe"
                    style={{ height: "100%", width: "100%", border: "none" }}
                />
            </div>
        );

    if (
        SelectedModule?.programModuleStatus &&
        SelectedModule.programModuleStatus[0] != 0
    )
        return (
            <div
                className="col-lg-7 col-md-12 mb-2"
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div
                    className="d-flex align-items-center justify-content-between py-3 px-3"
                    style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        borderBottom: "1px solid #e4ebf3",
                    }}
                >
                    <span
                        style={{
                            color: DARK_BLUE,
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        {SelectedModule.moduleTitle}
                    </span>
                    <div className="d-flex align-items-center">
                        <span
                            className="mx-1"
                            style={{
                                color: DARK_BLUE,
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                        >
                            Status:
                        </span>
                        <div>
                            {SelectedModule.programModuleStatus ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="mx-2"
                                        style={{
                                            backgroundColor: "#009704",
                                            height: "5px",
                                            width: "5px",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                    <span>Complete</span>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="mx-2"
                                        style={{
                                            backgroundColor: "#eb0400",
                                            height: "5px",
                                            width: "5px",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                    <span>Incomplete</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {AllQuestions.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                padding: "20px",
                                borderBottom: ".5px solid #eee",
                            }}
                        >
                            <p>
                                {
                                    item.Question?.questionDetail.startsWith("(")
                                        ? item.Question?.questionDetail
                                        : index + 1 + ") " + item.Question?.questionDetail
                                }
                            </p>
                            {item.Question?.answerTypeId == 20 ? (
                                <img
                                    src={item.lastAnswer?.value}
                                    alt="pain"
                                    style={{ width: "100%" }}
                                />
                            ) : item.Question?.answerTypeId == 13 ? (
                                moment(item.lastAnswer?.value).format(
                                    "MM/DD/YYYY"
                                )
                            ) : (
                                <p style={{ color: "#707478" }}>
                                    {item.lastAnswer?.value}
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        );

    return (
        <div className="col-lg-7 col-md-12 mb-2">
            <div className="card" style={{ border: 0 }}>
                <div
                    className="d-flex align-items-center justify-content-between py-3 px-3"
                    style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        borderBottom: "1px solid #e4ebf3",
                    }}
                >
                    <span
                        style={{
                            color: DARK_BLUE,
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        {SelectedModule.moduleTitle}
                    </span>
                    <div className="d-flex align-items-center">
                        <span
                            className="mx-1"
                            style={{
                                color: DARK_BLUE,
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                        >
                            Status:
                        </span>
                        <div>
                            {SelectedModule.programModuleStatus[0] != 0 ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="mx-2"
                                        style={{
                                            backgroundColor: "#009704",
                                            height: "5px",
                                            width: "5px",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                    <span>Complete</span>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="mx-2"
                                        style={{
                                            backgroundColor: "#eb0400",
                                            height: "5px",
                                            width: "5px",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                    <span>Incomplete</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <SurveryDialogContent
                    IsSurveyLoading={IsLoading}
                    SelectedSurvey={null}
                    CurrentQuestionIndex={CurrentQuestionIndex}
                    TotalQuestions={TotalQuestions}
                    ErrorMsg={ErrorMsg}
                    CurrentQuestion={AllQuestions[CurrentQuestionIndex]}
                    Finished={CurrentQuestionIndex >= TotalQuestions}
                    Last={CurrentQuestionIndex + 1 == TotalQuestions}
                    moveToNextQuestion={(answer) => moveToNextQuestion(answer)}
                    submitSurvey={(answers) => submitSurvey(answers)}
                    moveToPreviousQuestion={(answers) =>
                        moveToPreviousQuestion(answers)
                    }
                />
            </div>
        </div>
    );
};

export default DashboardSurvey;
