import { createSlice } from "@reduxjs/toolkit";

export const MessagesSlice = createSlice({
    name: "Messages",
    initialState: {
        Messages: [],
        Notes: []
    },
    reducers: {
        setMessages: (state, action) => {
            state.Messages = action.payload;

        },
        setNotes: (state, action) => {
            state.Notes = action.payload;

        },

    },
});

export const { setMessages, setNotes } = MessagesSlice.actions;


export const MessagesReducer = MessagesSlice.reducer;