import { createSlice } from "@reduxjs/toolkit";

export const SurveyAndMaterialSlice = createSlice({
    name: "SurveyAndMaterial",
    initialState: {
        SurveyAndMaterialData: [],
        SurveyList: [],
        sypLogSummaryId: "",


    },
    reducers: {
        setSurveyAndMaterialData: (state, action) => {
            state.SurveyAndMaterialData = action.payload;

        },
        setSurveyList: (state, action) => {
            state.SurveyList = action.payload;

        },
        setSypLogSummaryId: (state, action) => {
            state.sypLogSummaryId = action.payload;
        },


    },
});

export const { setSurveyAndMaterialData, setSurveyList, setSypLogSummaryId } = SurveyAndMaterialSlice.actions;


export const SurveyAndMaterialReducer = SurveyAndMaterialSlice.reducer;