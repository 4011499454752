import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getToken } from ".";
import { getUserID, getParams } from ".";
import { toast } from "react-toastify";
const urlParams = new URLSearchParams(window.location.search);
const isIframe = urlParams.get("isIframe");



export const GetAllFilesFolderList = (page = 0, parentId = 0, returnData = false, name) => async (dispatch, getState) => {
    try {

        const res = await axios.get(`${getBaseURL()}/FileFolder${getParams(getState)}&userId=${getUserID(getState)}&page=${page}&parentId=${parentId}&name=${name}`,);

        if (res.data.error) {
            return res.data.msg;
        }
        if (returnData) {
            return res.data.length > 0 ? false : "You can not share this folder as it is empty.";
        }

        if (!Array.isArray(res.data)) return "Documents loading failed";

        dispatch(ReducerActions.setDocumentData(res.data));
        dispatch(GetOnlyFolderList());

    } catch (error) {
        console.log(error);
    }
};

export const GetOnlyFolderList = (page = 0, parentId = 0) => async (dispatch, getState) => {
    try {

        const res = await axios.get(`${getBaseURL()}/FileFolder${getParams(getState)}&userId=${getUserID(getState)}&page=${page}&parentId=${parentId}&folders=yes`,);

       
        if (res.data.error) {
            return res.data.msg;
        }

        let rootFolders = res.data;

        for (let index = 0; index < rootFolders.length; index++) {
            let element = rootFolders[index];
            
            const res2 = await axios.get(`${getBaseURL()}/FileFolder${getParams(getState)}&userId=${getUserID(getState)}&page=${page}&parentId=${element.fileFolderId
            }&folders=yes`,);

            rootFolders[index].subFolders = res2.data;
        }


        // console.log(rootFolders,"rootFolders")
        dispatch(ReducerActions.setOnlyFolders(rootFolders));


    } catch (error) {
        console.log(error);
    }
};


export const CreateFolder = (folderName, parent = 0) => async (dispatch, getState) => {

    try {

        const body = [{
            "ownerId": getUserID(getState),
            "FName": folderName,
            "FNameOriginal": folderName,
            "parentFolderId": parent,
            "isProviderCreated": isIframe ? 1 : 0,
            "isNeedSignByPatient": 0,
            "isNeedSignByProvider": 0,
            "status": 1,
            "fileSize": 0,
            "fileTypeId": 8
        }];

        const res = await axios.post(`${getBaseURL()}/FileFolder/${getUserID(getState)}/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`, body);

        toast.success("Folder added successfully!");
        return;

    } catch (error) {
        console.log(error);
    }

};

export const DeleteFileFolder = (fileFolderId) => async (dispatch, getState) => {

    try {

        const res = await axios.get(`${getBaseURL()}/DeleteFileFolder/${fileFolderId}/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}&fileFolderId=${fileFolderId}`);

        toast.success("File/Folder deleted successfully!");
        return;
    } catch (error) {
        console.log(error);
    }

};

export const UploadFile = (file, parent = 0, image) => async (dispatch, getState) => {

    try {

        const formData = new FormData();

        if (image) {
            formData.append("file", file, `${Date.parse()}.png`);
        } else {
            formData.append("file", file);
        }
        formData.append("fileTypeId", 1);

        const res = await axios.post(`${getBaseURL()}/UploadDocFile/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`, formData);

        //toast.success("File uploaded successfully!")
        return res.data.result?.ActualImageURL || "";

    } catch (error) {
        console.log(error);
    }

};

export const SaveFiles = (body) => async (dispatch, getState) => {

    try {

        const res = await axios.post(`${getBaseURL()}/FileFolder/patientIdParam/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`, body);

        toast.success("File added successfully!");
        return;

    } catch (error) {
        console.log(error);
    }

};


export const ShareWithProviders = (body) => async (dispatch, getState) => {

    try {

        const res = await axios.post(`${getBaseURL()}/shareFileFolder${getParams(getState)}`, body);

        toast.success("File/Folder shared successfully!");
        return;

    } catch (error) {
        console.log(error);
    }

};

export const RenameFileFunction = (fileFolderId, Name) => async (dispatch, getState) => {
    try {

        const res = await axios.post(`${getBaseURL()}/FileFolder/${getUserID(getState)}/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`,
            [
                {
                    "FName": Name,
                    "fileFolderId": fileFolderId
                }
            ]
        );
        toast.success("File/Folder has been renamed successfully!");


    } catch (error) {
        toast.error("Something went wrong");
    }
};

export const UpdateStatus = (fileFolderShareUserRelId, shareStatus) => async (dispatch, getState) => {
    try {

        const res = await axios.post(`${getBaseURL()}/FileFolderShareUserRel/${getUserID(getState)}/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`,
            [
                {
                    "shareStatus": shareStatus,
                    "fileFolderShareUserRelId": fileFolderShareUserRelId
                }
            ]
        );
        // toast.success("File/Folder has been renamed successfully!")


    } catch (error) {
        toast.error("Something went wrong");
    }
};

export const MoveFileFolder = (fileFolderId, parentID) => async (dispatch, getState) => {
    try {

        const res = await axios.post(`${getBaseURL()}/FileFolder/${getUserID(getState)}/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`,
            [
                {
                    "parentFolderId": parentID,
                    "fileFolderId": fileFolderId
                }
            ]
        );
        toast.success("File/Folder moved successfully!");


    } catch (error) {
        toast.error("Something went wrong");
    }
};


export const downloadAs = (url, name) => dispatch => {
    axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream"
        },
        responseType: "blob"
    })
        .then(response => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = name;
            a.click();
        })
        .catch(err => {
            console.log("error", err);
        });
};