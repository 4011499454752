import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL, getOnlyBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";


export const GetAllHEP = (returnTId) => async (dispatch, getState) => {
    try {


        const res = await axios.get(`${ getBaseURL() }/GetLatestTreatmentDetails/${ getUserID(getState) }/0${ getParams(getState) }`,);

        if (res.data.error) {
            return res.data.msg;
        }

        if (returnTId) return res.data[0].treatmentId



        dispatch(ReducerActions.setHEPData(res.data[0] || null));


    } catch (error) {
        console.log(error);
    }
};


export const GetAllPastHeps = () => async (dispatch, getState) => {
    try {

        const res = await axios.get(`${ getBaseURL() }/GetTreatmentList/${ getUserID(getState) }/50/0${ getParams(getState) }&versionId=${ Date.parse(new Date()) }`);

        dispatch(ReducerActions.setPastHEPs(res.data));
    } catch (error) {
        dispatch(ReducerActions.setPastHEPs([]));
    }
};


export const GetAllPastVideos = (id, SelectedExerciseHistory) => async (dispatch, getState) => {
    try {
        console.log({ id, SelectedExerciseHistory })
        const res = await axios.get(`${ getBaseURL() }/GetVideosByTreatmentId/${ id }${ getParams(getState) }&versionId=${ Date.parse(new Date()) }`);

        // dispatch(ReducerActions.setPastVideos(res.data));
        dispatch(ReducerActions.setHEPData({
            ...SelectedExerciseHistory,
            ...res.data[0]
        }))
    } catch (error) {
        dispatch(ReducerActions.setPastVideos([]));
    }
};

export const PrintHepPDF = () => async (dispatch, getState) => {
    try {
        const HEPData = getState().HEP.HEPData;

        let treatmentVideoRelInfos = {};


        const videos = HEPData.Videos || HEPData.TherapyVideos;
        const treatmentName = HEPData.treatName || HEPData.Treatment.treatName;
        const endDate = HEPData.endDate || HEPData.Treatment.endDate;
        const startDate = HEPData.startDate || HEPData.Treatment.startDate;
        const treatmentData = HEPData.Treatment || HEPData;



        for (let index = 0; index < videos.length; index++) {
            const element = videos[index];

            treatmentVideoRelInfos = {
                ...treatmentVideoRelInfos,
                [element.TreatmentVideoRel.exerciseVideoId]: element.TreatmentVideoRel
            };
        }



        const body = {
            "patient_name": getState().User.UserData?.firstName + " " + getState().User.UserData?.lastName,
            "treatmentName": treatmentName,
            "endDate": endDate,
            "stratDate": startDate,
            "enableTimeRange": true,
            "patientphoto": "",
            "therapist": "",
            "phonenumber": getState().User.UserData?.phoneNo || "",
            "userLocTimeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            "adminDocterId": videos[0].adminDocterId,
            "patientId": getState().User.UserData.userId,
            "treatmentVideoRelInfos": JSON.stringify(treatmentVideoRelInfos),
            "json": JSON.stringify(videos),
            "treatmentdata": JSON.stringify(treatmentData),
        };

        const formBody = new FormData();

        for (let index = 0; index < Object.keys(body).length; index++) {
            const element = body[Object.keys(body)[index]];

            formBody.append(Object.keys(body)[index], element);
        }

        // const res = await axios.post(`${getOnlyBaseURL()}/libs/usr_db_treatment_reportpdf_new.php`,formBody) 


        const myForm = `
        <form target="blank" id="TheForm" action=${ getOnlyBaseURL() }/libs/usr_db_treatment_reportpdf_new.php method="post" enctype="multipart/form-data">
            <input value=${ body.patient_name } name="patient_name">
            <input value=${ body.treatmentName } name="treatmentName">
            <input value=${ body.endDate } name="endDate">
            <input value=${ body.stratDate } name="stratDate">
            <input value=${ body.enableTimeRange } name="enableTimeRange">
            <input value=${ body.patientphoto } name="patientphoto">
            <input value=${ body.therapist } name="therapist">
            <input value=${ body.phonenumber } name="phonenumber">
            <input value=${ body.userLocTimeZone } name="userLocTimeZone">
            <input value=${ body.adminDocterId } name="adminDocterId">
            <input value=${ body.patientId } name="patientId">
            <input value=${ body.treatmentVideoRelInfos } name="treatmentVideoRelInfos">
            <input value=${ body.json } name="json">
            <input value=${ body.treatmentdata } name="treatmentdata">
        </form>
        `;
        document.getElementById("forTheForm").innerHTML = myForm;

        document.getElementById("TheForm").submit();
        // alert(typeof res.data)
    } catch (error) {
        console.log(error);
    }
};


export const UpdateTreatmentCheckSum = (id) => async (dispatch, getState) => {

    const res = await axios.get(`${ getBaseURL() }/UpdateTreatmentCheckSum/${ id }${ getParams(getState) }`);
};

export const submitLogProgressData = (percentage, tId) => async (dispatch, getState) => {

    const res = await axios.post(`${ getBaseURL() }/submitLogProgressData/${ getParams(getState) }`,
        {
            "movementV": -1,
            "painV": -1,
            "complementV": percentage,
            "userId": getUserID(getState),
            "treatmentid": tId
        });
};