import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LIGHT_GREEN } from "../../constants";
import DialogWrapper from "../../helpers/DialogWrapper";
import * as Actions from "../../store/actions";
import check from "../../assests/icons/check.svg";
import checkActive from "../../assests/icons/checkActive.svg";
const ChangePasswordDialog = () => {


    const dispatch = useDispatch();

    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [IsLoading, setIsLoading] = useState(false);
    const [ErrorMsg, setErrorMsg] = useState("");


    const UserData = useSelector(state => state.User.UserData);


    async function onSubmit() {
        try {
            setErrorMsg(null);
            setIsLoading(true);

            // if (!NewPassword) throw "Please enter your new password.";
            if (NewPassword.length < 8 || !(/\d/.test(NewPassword)) || !(/[!@#$%&?]+/.test(NewPassword))) throw "Please follow the guidelines.";
            if (ConfirmPassword !== NewPassword) throw "Please follow the guidelines.";

            const body = {
                "userId": UserData.userId,
                "email": UserData.email,
                "lastName": UserData.lastName,
                "firstName": UserData.firstName,
                "address": UserData.address,
                "phoneNo": UserData.phoneNo,
                "birthDate": UserData.birthDate,
                "pwd": NewPassword,
                CountryId: UserData.CountryCode,
                isActive: "1"
            };

            await dispatch(Actions.UpdateProfile(body, ""));

            setIsLoading(false);

        } catch (error) {
            setErrorMsg(error);
            setIsLoading(false);
        }
    }


    return (
        <DialogWrapper
            open={true}
            maxWidth={"sm"}
            title={"Please change your password"}
            hideCrossButton
            onClose={() => { }}
            content={
                <>
                    <div style={{
                        height: "250px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        // alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden",
                        padding: "10px",
                        flexWrap: "wrap"
                    }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <TextField
                                label="New Password"
                                variant="outlined"
                                type={"password"}
                                autoComplete="new-password"
                                style={{ width: "49%", }}
                                value={NewPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                type={"password"}
                                autoComplete="new-password"
                                style={{ width: "49%",}}
                                value={ConfirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                       
                        <div style={{ }}>
                            <div className="mt-2 d-flex align-items-center" >
                                <img alt="img" src={NewPassword.length < 8 ? check : checkActive} />
                                <span className="ml-2" style={{ color: NewPassword.length < 8 ? "#333" : "black", fontSize: "12px", opacity: NewPassword.length < 8 ? "0.5" : "1" }} >
                                    8 characters minimum
                                </span>
                            </div>
                            <div className="mt-2 d-flex align-items-center" >
                                <img alt="img" src={!(/\d/.test(NewPassword)) ? check : checkActive} />
                                <span className="ml-2" style={{ color: !(/\d/.test(NewPassword)) ? "#333" : "black", fontSize: "12px", opacity: !(/\d/.test(NewPassword)) ? "0.5" : "1" }} >
                                    One number
                                </span>
                            </div>
                            <div className="mt-2 d-flex align-items-center" >
                                <img alt="img" src={!(/[!@#$%&?]+/.test(NewPassword)) ? check : checkActive} />
                                <span className="ml-2" style={{ color: !(/[!@#$%&?]+/.test(NewPassword)) ? "#333" : "black", fontSize: "12px", opacity: !(/[!@#$%&?]+/.test(NewPassword)) ? "0.5" : "1" }} >
                                    One special character (Allowed Characters : !@#$%&?)
                                </span>
                            </div>
                            <div className="mt-2 d-flex align-items-center" >
                                <img alt="img" src={!NewPassword || NewPassword !== ConfirmPassword ? check : checkActive} />
                                <span className="ml-2" style={{ color: !NewPassword || NewPassword !== ConfirmPassword ? "#333" : "black", fontSize: "12px", opacity: !NewPassword || NewPassword !== ConfirmPassword ? "0.5" : "1" }} >
                                    Password and confirm password match
                                </span>
                            </div>
                        </div>
                        <p style={{ color: "red" }}>
                            {ErrorMsg}
                        </p>
                    </div>
                </>
            }
            footerButtons={
                <>

                    {
                        IsLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ background: LIGHT_GREEN }}
                                onClick={() => onSubmit()}
                            >
                                Submit
                            </Button>
                        )
                    }
                </>
            }

        />
    );
};

export default ChangePasswordDialog;