import { createSlice } from "@reduxjs/toolkit";

export const HEPSlice = createSlice({
    name: "HEP",
    initialState: {
        HEPData: null,
        PastHEP: null,
        PastVideos: [],
    },
    reducers: {
        setHEPData: (state, action) => {
            state.HEPData = action.payload;
        },
        setPastHEPs: (state, action) => {
            state.PastHEP = action.payload;
        },
        setPastVideos: (state, action) => {
            state.PastVideos = action.payload;
        },

    },
});

export const { setHEPData, setPastHEPs, setPastVideos } = HEPSlice.actions;


export const HEPReducer = HEPSlice.reducer;