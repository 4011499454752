import React, { useEffect, useState } from "react";
import Calender from "./Calender";
import { DARK_BLUE } from "../../constants";
import DashboardHEP from "./DashboardHEP";
import DashboardEduMaterialCard from "./DashboardEduMaterialCard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import arrowRight from "../../assests/icons/arrowRight.svg";
import DashboardTask from "./DashboardTask";
import DashboardTelehealth from "./DashboardTelehealth";
import DashboardSurvey from "./DashboardSurvey";
import ProgramInfo from "./ProgramInfo";

const CurrentProgramMainScreen = props => {

    let id = null;
    const theID = useParams().id;
    if (props.fromEngage) {
        id = props.SelectedProgram;
    } else {
        id = theID;
    }
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    // const [TypeOfRight, setTypeOfRight] = useState(2); // 1 edu // 2 hep // 3 survey  // telehealth
    const [SelectedTab, setSelectedTab] = useState(1);
    const [CurrentProgram, setCurrentProgram] = useState({});
    const [IsLoading, setIsLoading] = useState(true);
    const [IsModuleLoading, setIsModuleLoading] = useState(true);
    const [CurrentDate, setCurrentDate] = useState("");
    const [CurrentDateTasks, setCurrentDateTasks] = useState([]);
    const [SelectedModule, setSelectedModule] = useState({});
    const [SelectedModuleIndex, setSelectedModuleIndex] = useState(0);
    const [SelectedModuleData, setSelectedModuleData] = useState(null);
    const [SelectedModuleType, setSelectedModuleType] = useState(""); // E-material // HEP // Survey // Telehealth Session // Task //

    const AllJoinedPrograms = useSelector((state) => state.Programs.AllJoinedProgramsFromThisUser);


    useEffect(() => {
        if (CurrentDate) {
          
            const tasks = CurrentProgram.Modules.filter(task => moment(task.moduleStartDate * 1000).format("YYYY-MM-DD") === CurrentDate);
            if (tasks.length > 0) {
                setCurrentDateTasks(tasks);
                setSelectedModule(tasks[0]);
                setSelectedModuleIndex(0);
                getModuleDetails(tasks[0].programModuleId, tasks[0].moduleTypeLkpId);
                setSelectedModuleType(tasks[0].moduleName);
            } else {
                setCurrentDateTasks([]);
                setSelectedModule({});
                setSelectedModuleIndex(0);
                setIsModuleLoading(false);
                setSelectedModuleType("");
            }

        }
    }, [CurrentDate]);

    useEffect(() => {
        if (AllJoinedPrograms.length > 0) {
            setIsLoading(true);
            setCurrentDateTasks([]);
            setSelectedModule({});
            setSelectedModuleIndex(0);
            setIsModuleLoading(false);
            setSelectedModuleType("");
            const program = AllJoinedPrograms.filter(x => x.programId === id)[0];
            setCurrentProgram(program);
            setIsLoading(false);

        }
    }, [AllJoinedPrograms, id]);


    async function getModuleDetails(pid, mid) {
        setIsModuleLoading(true);
        const res = await dispatch(Actions.getDataWithProgramAndModuleIdForJoinedPatient(pid, mid));
        setIsModuleLoading(false);
        setSelectedModuleData(res);

    }

    async function onClickAnyFileForMaterial(value) {
        dispatch(Actions.SavePatientMaterialLogSummary(value.sypLogTypeId, value.exerciseVideoId, Math.floor(Date.now() / 1000), Math.floor(Date.now() / 1000)));
    }


    if (IsLoading) return (
        <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>
    );

    return (
        <div className="Documents pt-1 pb-5" style={{ backgroundColor: "#f8fafd", }} >
            <div className={"container-fluid"} >
                {
                    props.fromEngage ? null : (
                        <>
                            <h5 className="py-3" >
                                Dashboard
                            </h5>
                        </>
                    )
                }
                <div style={{ background: "white" }}>
                    {
                        props.fromEngage ? null : (
                            <div style={{
                                height: "100px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    height: "50%",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                    borderBottom: "1px solid #a1c6df"
                                }}>
                                    <p style={{ padding: "0", margin: "0", fontWeight: "bold" }}>
                                        {CurrentProgram.title}
                                    </p>
                                </div>
                                <div style={{
                                    height: "50%",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                    borderBottom: "1px solid #a1c6df"
                                }}>
                                    <p
                                        onClick={() => setSelectedTab(1)}
                                        style={{
                                            padding: "0",
                                            margin: "0",
                                            marginRight: "30px",
                                            color: SelectedTab === 1 ? DARK_BLUE : "grey",
                                            borderBottom: SelectedTab === 1 ? `3px solid ${DARK_BLUE}` : "none",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }}>
                                        Program Module
                                    </p>
                                    <p
                                        onClick={() => setSelectedTab(2)}
                                        style={{
                                            padding: "0",
                                            margin: "0",
                                            marginRight: "30px",
                                            color: SelectedTab === 2 ? DARK_BLUE : "grey",
                                            borderBottom: SelectedTab === 2 ? `3px solid ${DARK_BLUE}` : "none",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }}>
                                        Program Information
                                    </p>
                                </div>


                            </div>
                        )
                    }

                    <div style={{ display: SelectedTab === 2 ? "none" : "" }} className="card-body pt-0" >
                        <div className="row" >
                            <div className="col-lg-5 col-md-12 mb-2" style={{ borderRight: "1px solid #9e9e9e" }} >
                                <div className="card mb-2" style={{ border: 0 }} >

                                    <Calender
                                        ChangeDate={(date) => setCurrentDate(date)}
                                        Dates={CurrentProgram?.Dates}
                                        ModuleWithDates={CurrentProgram?.ModulesWithDatesObj}
                                        id={id}
                                        Modules={CurrentProgram?.Modules}
                                        InitDateForCalendar={CurrentProgram?.startDate}
                                    />
                                </div>
                                <div className="card mb-2" style={{ border: 0 }} >
                                    <div className="d-flex align-items-center justify-content-between py-2 px-2"
                                        style={{ fontSize: "13px", fontWeight: "500", borderBottom: "1px solid #e4ebf3" }}
                                    >
                                        <span style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: "bold" }} >
                                            Tasks
                                        </span>
                                    </div>
                                    <div className="card-body" style={{ maxHeight: "65vh", overflowY: "scroll" }} >
                                        {
                                            CurrentDateTasks.length === 0 ? (
                                                <h3 style={{ color: "grey", textAlign: "center" }}>No Tasks</h3>
                                            ) : null
                                        }
                                        {
                                            CurrentDateTasks.map((value, index) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setSelectedModule(value);
                                                            setSelectedModuleType(value.moduleName);
                                                            setSelectedModuleIndex(index);
                                                            getModuleDetails(value.programModuleId, value.moduleTypeLkpId);
                                                        }}
                                                        key={index}
                                                        className="row py-2 px-3 justify-content-between align-items-center mb-2"
                                                        style={{ border: "1px solid #1c58917d", borderRadius: "5px", color: "#2a2a2a", background: SelectedModuleIndex === index ? "#e4ebf3" : "#fff", border: SelectedModuleIndex === index ? "0" : "1px solid #e4ebf3" }} >
                                                        <div className="pr-1 pl-1 col-3" >
                                                            <p style={{ fontWeight: "bold", fontSize: "16px" }} >
                                                                Task {index + 1}
                                                            </p>
                                                        </div>
                                                        <div className="pl-2 pr-1 col-8" >
                                                            <div
                                                                id="groupType"
                                                                className="d-flex align-items-start justify-content-between py-2 px-3"
                                                                style={{ cursor: "pointer", fontSize: "13px", fontWeight: "500", borderRadius: "5px" }}
                                                                onClick={() => {

                                                                }
                                                                }
                                                            >
                                                                <div>
                                                                    <span style={{ color: DARK_BLUE, fontSize: "14px", fontWeight: "500" }} >
                                                                        {
                                                                            value.moduleTitle
                                                                        }
                                                                    </span>
                                                                    <p className="mb-1" style={{ color: "#7b7b7b", fontSize: "13px" }} >
                                                                        {value.moduleName}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="nextIcon col-1" >
                                                            <img src={arrowRight} alt="next" />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                            {
                                IsModuleLoading ? (
                                    <div className="col-lg-7 col-md-12 mb-2" style={{ height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            !SelectedModuleType ? (
                                                <div className="col-lg-7 col-md-12 mb-2" style={{ height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <h3 style={{ color: "grey" }}>No Task Selected</h3>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            SelectedModuleType === "E-material" ? (
                                                <DashboardEduMaterialCard
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                    onClickAnyFile={onClickAnyFileForMaterial}
                                                />

                                            ) : null
                                        }

                                        {
                                            SelectedModuleType === "HEP" ? (
                                                <DashboardHEP
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                />

                                            ) : null
                                        }
                                        {
                                            SelectedModuleType === "Task" ? (
                                                <DashboardTask
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                />

                                            ) : null
                                        }
                                        {
                                            SelectedModuleType === "Telehealth Session" ? (
                                                <DashboardTelehealth
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                />

                                            ) : null
                                        }
                                        {
                                            SelectedModuleType === "Phone Call" ? (
                                                <DashboardTask
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                    IsPhoneCall={true}
                                                />

                                            ) : null
                                        }
                                        {
                                            SelectedModuleType === "Survey" ? (
                                                <DashboardSurvey
                                                    SelectedModule={SelectedModule}
                                                    SelectedModuleData={SelectedModuleData}
                                                />

                                            ) : null
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div style={{ display: SelectedTab === 1 ? "none" : "" }} className="card-body pt-0" >
                        <ProgramInfo
                            CurrentProgram={CurrentProgram}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


};

CurrentProgramMainScreen.propTypes = {};

export default CurrentProgramMainScreen;