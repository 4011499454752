import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import carrotBlue from "../../assests/icons/carrotBlue.svg";
import DialogWrapper from "../../helpers/DialogWrapper";
import * as Actions from "../../store/actions";
import MaterialBoxes from "./MaterialBoxes";

const MaterialContent = props => {


    const dispatch = useDispatch();

    const [VideoDialog, setVideoDialog] = useState(false);
    const [VideoPath, setVideoPath] = useState("");
    const [ActiveAccordian, setActiveAccordian] = useState(null);

    const [DialogOpenTime, setDialogOpenTime] = useState("");
    const [OpenedVideosypLogTypeId, setOpenedVideosypLogTypeId] = useState("");
    const [OpenedVideoExerciseVideoId, setOpenedVideoExerciseVideoId] = useState("");




    return (
        <div className="card border-0 pb-3 mt-4" >
            <div className="py-2 px-3"
                style={{ color: "#333333", fontSize: "18px", fontWeight: "500", borderBottom: "1px solid #e4ebf3" }}
            >
                Material
            </div>
            <div className="card-body" >
                {
                    props.IsLoadingMaterial ? (
                        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    ) : null
                }
                {
                    !props.IsLoadingMaterial && props.SurveyAndMaterialData.length === 0 ? (
                        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "grey" }}>No material found</h3>
                        </div>
                    ) : null
                }
                <TableContainer hidden={props.IsLoadingMaterial || props.SurveyAndMaterialData.length === 0} >
                    <Table className="" aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell
                                    style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                                >
                                    Start Date
                                </TableCell>
                                <TableCell
                                    style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                                >
                                    End Date
                                </TableCell>
                                <TableCell
                                    style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                                >
                                    Repeat For
                                </TableCell>
                                <TableCell
                                    style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                                >
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !props.IsLoadingMaterial && props.SurveyAndMaterialData?.map((value, index) => {
                                    return (
                                        <>
                                            <TableRow
                                                key={index}
                                                onClick={() => {
                                                    if (ActiveAccordian == index) return setActiveAccordian(null);
                                                    setActiveAccordian(index);
                                                    // document.getElementById("Material" + index).scrollIntoView({ behavior: "smooth", block: "start" });
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                                    {value.SURVEY_TASK.taskName}
                                                </TableCell>
                                                <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                                    {moment(new Date(parseInt(value.SURVEY_TASK.startDate * 1000))).format("MMM Do YYYY")}
                                                </TableCell>
                                                <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                                    {moment(new Date(parseInt(value.SURVEY_TASK.endDate * 1000))).format("MMM Do YYYY")}
                                                </TableCell>
                                                <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                                    {value.SURVEY_TASK.repAmount} {value.SURVEY_TASK.repUnit}
                                                </TableCell>
                                                <TableCell align="center" style={{ color: "#6A6A6A" }} component="th" scope="row">
                                                    <img alt="icon" src={carrotBlue} style={{ cursor: "pointer", color: "#2570b7", transform: ActiveAccordian == index ? "rotate(180deg)" : null, width: "12px" }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hidden={ActiveAccordian != index}>
                                                <TableCell colSpan={5}>
                                                    <MaterialBoxes
                                                        index={index}
                                                        videos={value.EDUCATION_MATERIALS}
                                                        onClickVideoPlay={(value2) => {
                                                            setVideoDialog(true);
                                                            setVideoPath("https://d3i9v5bkcvmkzb.cloudfront.net/" + value2.filename);
                                                            setDialogOpenTime(new Date().toISOString());
                                                            setOpenedVideosypLogTypeId(value.SURVEY_TASK.tblPKId);
                                                            setOpenedVideoExerciseVideoId(value2.exerciseVideoId);
                                                        }}
                                                        onClickAnyFile={(value2) => dispatch(Actions.SavePatientMaterialLogSummary(value.SURVEY_TASK.tblPKId, value2.exerciseVideoId, Math.floor(Date.now() / 1000), Math.floor(Date.now() / 1000)))}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {VideoDialog ?
                <DialogWrapper
                    NoFooter
                    open={VideoDialog}
                    title={
                        <>
                            <h4 className="mb-1" style={{ color: "#333333" }} >
                                Video
                            </h4>
                        </>
                    }
                    titleColor="#333"
                    onClose={() => {
                        setVideoDialog(false);
                        dispatch(Actions.SavePatientMaterialLogSummary(OpenedVideosypLogTypeId, OpenedVideoExerciseVideoId, Math.floor(Date.now(DialogOpenTime) / 1000), Math.floor(Date.now() / 1000)));
                    }}
                    content={
                        <video width="100%" height="500" controls autoPlay >
                            <source src={VideoPath} />
                        </video>
                    }
                /> : null
            }

        </div>
    );
};

MaterialContent.propTypes = {

};

export default MaterialContent;
