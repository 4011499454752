import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
// const useStyles = makeStyles({
//     table: {
//         minWidth: 650,
//     },
// });


const SurveyTable = props => {
    return (
        <TableContainer >
            <Table className="" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                        >
                            Name
                        </TableCell>
                        <TableCell
                            style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                        >
                            Start Date
                        </TableCell>
                        <TableCell
                            style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                        >
                            End Date
                        </TableCell>
                        <TableCell
                            style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                        >
                            Rep Count
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ backgroundColor: "white", color: "black", fontWeight: "550" }}
                        >
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.data.map((value, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                        {value.name}
                                    </TableCell>
                                    <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                        {moment(parseInt(value.startDate * 1000)).format("MM/DD/YYYY")}
                                    </TableCell>
                                    <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                        {moment(parseInt(value.endDate * 1000)).format("MM/DD/YYYY")}
                                    </TableCell>
                                    <TableCell style={{ color: "#6A6A6A" }} component="th" scope="row">
                                        {/* {value.repAmount} {value.repUnit} */}
                                        {
                                            `${value.repAmount} ${value.repUnit}` === "1 days" ? "1 day" :
                                                `${value.repAmount} ${value.repUnit}` === "1 months" ? "1 month" :
                                                    `${value.repAmount} ${value.repUnit}` === "1 weeks" ? "1 week" : `${value.repAmount} ${value.repUnit}`
                                        }
                                    </TableCell>
                                    <TableCell align="right" style={{ color: "#6A6A6A" }} component="th" scope="row">
                                        {
                                            value.hasOtherLanguage == 1 ? (
                                                <>
                                                    <button className="btn py-1 px-2"
                                                        disabled={value.timeCheck.status != 1}
                                                        style={{ borderColor: "#2570b7", fontSize: "14px", backgroundColor: "white", color: "#2570b7" }}
                                                        onClick={() => props.onStart(value)}
                                                    >
                                                        {
                                                            value.timeCheck.status != 1 ? "Completed" : "Get Started (English)"
                                                        }

                                                    </button>
                                                    <button hidden={value.timeCheck.status != 1} className="btn py-1 px-2 ml-2"
                                                        disabled={value.timeCheck.status != 1}
                                                        style={{ borderColor: "#2570b7", fontSize: "14px", backgroundColor: "white", color: "#2570b7" }}
                                                        onClick={() => props.onStart(value, true)}
                                                    >
                                                        {
                                                            value.timeCheck.status != 1 ? "Completed" : "Get Started (Spanish)"
                                                        }

                                                    </button>
                                                </>
                                            ) : (
                                                <button className="btn py-1 px-2"
                                                    disabled={value.timeCheck.status != 1}
                                                    style={{ borderColor: "#2570b7", fontSize: "14px", backgroundColor: "white", color: "#2570b7" }}
                                                    onClick={() => props.onStart(value)}
                                                >
                                                    {
                                                        value.timeCheck.status != 1 ? "Completed" : "Get Started"
                                                    }

                                                </button>
                                            )
                                        }

                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer >
    );
};

SurveyTable.propTypes = {

};

export default SurveyTable;
