import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";

const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get("userId");


export const InitFitbit = () => async (dispatch, getState) => {
    try {

        const URL = `${getBaseURL()}/BJOAuthInit${getParams(getState)}&patient_id=${userId || getUserID(getState)}${userId ? `&patientIdParam=${userId}` : ""}`;

        window.location.href = URL;

    } catch (error) {
        console.log(error);
    }
};

export const getLast30DaysData = (code, state) => async (dispatch, getState) => {
    for (let index = 1; index <= 15; index++) {
        axios.get(`${getBaseURL()}/BJOAuthHealthData${getParams(getState)}&patient_id=${userId || getUserID(getState)}&code=${code}&state=${state}&date=${moment(new Date()).subtract(index, "day").format("YYYY-MM-DD")}${userId ? `&patientIdParam=${userId}` : ""}`);
    }
};


export const saveTokenForFitbit = (code, state) => async (dispatch, getState) => {
    try {

        await axios.get(`${getBaseURL()}/BJOAuthAccessToken${getParams(getState)}&patient_id=${userId || getUserID(getState)}&code=${code}&state=${state}${userId ? `&patientIdParam=${userId}` : ""}`);

        await axios.get(`${getBaseURL()}/BJOAuthHealthData${getParams(getState)}&patient_id=${userId || getUserID(getState)}&code=${code}&state=${state}&date=${moment(new Date()).format("YYYY-MM-DD")}${userId ? `&patientIdParam=${userId}` : ""}`);

        // dispatch(getLast30DaysData(code, state)); handled on backend side

    } catch (error) {
        console.log(error);
    }
};

let checkForConnection = true;


export const getFitBitActivitiesForTable = (date = new Date(), page) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/FitbitActivityDataByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}&page=${page}${userId ? `&patientIdParam=${userId}` : ""}`);

        dispatch(ReducerActions.setActivityDataForTable(res.data));
        
        if(res.data.length < 5) {
            dispatch(ReducerActions.setIsLoadMoreVisible(false));
        }

    } catch (error) {
        console.log(error);
    }
};

export const getFitBitdata = (date = new Date()) => async (dispatch, getState) => {
    try {

        const Weight = axios.get(`${getBaseURL()}/FitbitWeightByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);
        const Activity = axios.get(`${getBaseURL()}/FitbitActivityDataByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);
        const Food = axios.get(`${getBaseURL()}/FoodDataByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);
        const Sleep = axios.get(`${getBaseURL()}/FitbitSleepDataByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);
        const HRV = axios.get(`${getBaseURL()}/FitbitHRVDataByUserId/${userId || getUserID(getState)}/${moment(date).format("YYYY-MM-DD")}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);


        const res = await Promise.all([Weight, Activity, Food, Sleep, HRV]);

        dispatch(ReducerActions.setWeightData(res[0].data));
        dispatch(ReducerActions.setActivityData(res[1].data));
        dispatch(ReducerActions.setFoodData(res[2].data));
        dispatch(ReducerActions.setSleepData(res[3].data));
        dispatch(ReducerActions.setHRVData(res[4].data));

        if (!checkForConnection) return;

        const needToConnectRes = await axios.get(`${getBaseURL()}/BJOAuthCheck/${userId || getUserID(getState)}${getParams(getState)}${userId ? `&patientIdParam=${userId}` : ""}`);

        if (needToConnectRes.data.status == 1) {
            checkForConnection = false;
            dispatch(ReducerActions.setNeedToConnect(false));
        }
        if (needToConnectRes.data.status == 2) {

        }
        if (needToConnectRes.data.status == 3) {
            toast.error("Your fitbit session has expired, please connect again!");
        }


    } catch (error) {
        console.log(error);
    }
};