import { Button, InputAdornment, LinearProgress, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { DARK_BLUE, LIGHT_GREEN } from "../../constants";
import SearchIcon from "@mui/icons-material/Search";
import Tasks from "../dashboard/Tasks";

const useStyles = makeStyles({
    root: {
        width: "93%",
    },
});
const GoalsAndOutcomesScreen = () => {
    const classes = useStyles();
    const [progress, setProgress] = useState(10);

    return (
        <div className="dashboard pt-1 pb-5" >
            <div className="container-fluid" >
                <h4 className="py-3" >
                    Goal & Health Data
                </h4>
                <div className="card pb-3" style={{ border: 0, borderRadius: 0 }} >
                    <div className="tabsMenu py-2" style={{ borderBottom: "1px solid #e4ebf3" }} >
                        <span
                            className="mx-3 " style={{ cursor: "pointer", fontSize: "16px", color: DARK_BLUE, fontWeight: "500", borderBottom: `2px solid ${DARK_BLUE}`, paddingBottom: "10px", cursor: "pointer", opacity: "1", }} >
                            Goal & Outcomes
                        </span>
                        <span
                            className="mx-3 " style={{
                                cursor: "pointer", fontSize: "16px", color: "#003333", opacity: "0.5", cursor: "pointer", borderBottom: "",
                                paddingBottom: "10px",
                            }} >
                            Health Data
                        </span>
                    </div>
                    <div className="card-body" >
                        <div className="searchBar d-flex align-items-center justify-content-between pb-3" style={{ borderBottom: "1px solid #e4ebf3" }} >
                            <div className="date" >
                                <span>
                                    Apr 19 - 25, 2021
                                </span>
                                <button className="btn py-1 px-2 mx-2"
                                    style={{ borderColor: "#2570b7", fontSize: "14px", backgroundColor: "white", color: "#2570b7" }}
                                >
                                    Change Date
                                </button>
                            </div>
                            <div className="search" >
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: "250px", marginRight: "20px" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ borderBottom: "1px solid #e4ebf3" }}  >
                            <div className="row mt-3" >
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ fontWeight: "500" }} >
                                        Title
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ fontWeight: "500" }} >
                                        Frequency
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ fontWeight: "500" }} >
                                        Duration
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ fontWeight: "500" }} >
                                        Progress
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ borderBottom: "1px solid #e4ebf3", backgroundColor: "#2570b708" }}  >
                            <div className="row pt-3" >
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ fontWeight: "500" }} >
                                        70 lb body weight loss
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ color: "#7b7b7b" }} >
                                        Weekly
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <p style={{ color: "#7b7b7b" }} >
                                        May 19, 2021 to Jul 19, 2021
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3" >
                                    <div className="d-flex justify-content-between align-items-center " >
                                        <p className="mb-0" style={{ fontSize: "14px", fontWeight: "500", color: "#7b7b7b" }} >
                                            247 lb
                                        </p>
                                        <p className="mb-0" style={{ fontSize: "14px", fontWeight: "500", color: "#7b7b7b" }} >
                                            177 lb
                                        </p>
                                    </div>
                                    <div className={classes.root}>
                                        <LinearProgress
                                            color="primary"
                                            variant="determinate"
                                            value={progress}
                                            style={{ height: "2px", backgroundColor: "#e4ebf3" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ backgroundColor: "#fff" }} >
                                <div className="buttonsDiv d-flex align-items-center justify-content-between py-2" >
                                    <div className="buttons" >
                                        <Button
                                            variant="contained"
                                            style={{ textTransform: "capitalize", color: "white", backgroundColor: LIGHT_GREEN }}
                                        >
                                            Goal's Taks
                                        </Button>
                                        <button className="btn py-2 px-2 mx-2"
                                            style={{ borderColor: "#e4ebf3", fontSize: "14px", backgroundColor: "white", color: "#333333" }}
                                        >
                                            Progress Chart
                                        </button>
                                    </div>
                                    <div className="inputButton" >
                                        <Button
                                            variant="contained"
                                            style={{ textTransform: "capitalize", color: "white", backgroundColor: DARK_BLUE }}
                                        >
                                            Input
                                        </Button>
                                    </div>
                                </div>
                                <Tasks />
                            </div>
                        </div>
                    </div>
                    {/* {
                        Index === 1 ? (
                            <TodaysTask
                                IsLoadingHEP={IsLoadingHEP}
                                HepData={HepData}
                                ErrorMsgHEP={ErrorMsgHEP}
                            />
                        ) : null
                    }
                    {
                        Index === 2 ? <HealthStatus /> : null
                    } */}
                </div>
            </div>
        </div >
    );
};

export default GoalsAndOutcomesScreen;
