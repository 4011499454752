import React from "react";
// import PropTypes from "prop-types";
import playBlue from "../../assests/icons/playBlue.svg";
import audio from "../../assests/icons/mp3.svg";
import text from "../../assests/icons/txt.svg";
import ppt from "../../assests/icons/ppt.svg";
import pdfIconWhite from "../../assests/images/pdfWhite.png";
import doc from "../../assests/icons/doc.svg";
import { Button } from "@mui/material";
import { DARK_BLUE, LIGHT_GREEN } from "../../constants";
// import { useDispatch } from "react-redux";

const MaterialBoxes = (props) => {


    // const dispatch = useDispatch();

    return (
        <div className={props.fromOnlyMB ? "container" : ""} id={"Material" + props.index} style={{ minHeight: "200px", width: "100%", display: "flex", flexWrap: "wrap", paddingTop: props.fromOnlyMB ? "80px" : "" }}>
            <h1 style={{ width: "100%", textAlign: "center", padding: "20px" }}>{props.taskName}</h1>
            <br />
            {
                props.videos.map((value2, index2) => {
                    return (
                        <div key={index2} className="col-lg-3 col-md-6 col-sm-12 px-2 mt-3" >
                            <div className="card border-0" style={{ borderRadius: 0 }} >
                                <div
                                    onClick={() => {
                                        if (value2.fileTypeId == "2") {
                                            props.onClickVideoPlay(value2);
                                            return;
                                        }
                                        props.onClickAnyFile(value2);
                                        window.open("https://d3i9v5bkcvmkzb.cloudfront.net/" + value2.filename, "_blank");
                                    }}
                                    style={{ height: "150px", width: "100%", backgroundImage: value2.fileTypeId == "1" ? "url(https://d3i9v5bkcvmkzb.cloudfront.net/" + value2.filename + ")" : "url(https://dfi4wfwfroz6l.cloudfront.net/" + value2.image + ")", backgroundSize: "cover", backgroundColor: LIGHT_GREEN, overflow: "hidden", cursor: "pointer" }}

                                    className="d-flex justify-content-center align-items-center"
                                >
                                    {value2.fileTypeId === "1" ? <img alt="icon" style={{ width: "100%" }} src={"https://d3i9v5bkcvmkzb.cloudfront.net/" + value2.filename} /> : null}
                                    {value2.fileTypeId === "2" ? <img alt="icon" style={{ width: "45px", cursor: "pointer" }} src={playBlue}
                                        onClick={() => {
                                            props.onClickVideoPlay(value2);
                                        }} /> : null
                                    }
                                    {value2.fileTypeId === "3" ? <img alt="icon" style={{ width: "45px" }} src={audio} /> : null}
                                    {value2.fileTypeId === "4" ? <img alt="icon" style={{ width: "45px" }} src={text} /> : null}
                                    {value2.fileTypeId === "5" ? <img alt="icon" style={{ width: "45px" }} src={pdfIconWhite} /> : null}
                                    {value2.fileTypeId === "6" ? <img alt="icon" style={{ width: "45px" }} src={ppt} /> : null}
                                    {value2.fileTypeId === "7" ? <img alt="icon" style={{ width: "45px" }} src={doc} /> : null}
                                    {/* <img alt="icon" style={{width:"45px"}} src={audio} /> */}
                                </div>
                                <div style={{ backgroundColor: "#f8f8f8" }} >
                                    <p className="my-2 pb-1 mx-2" style={{ color: "#333333", fontSize: "18px", borderBottom: "1px solid #e4ebf3", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} >
                                        {value2.videoName}
                                    </p>
                                    <div className="mx-2 my-2">
                                        <div className="d-flex align-items-center justify-content-between" >
                                            {
                                                value2.fileTypeId === "2" ?
                                                    <Button
                                                        variant="contained"
                                                        style={{ background: DARK_BLUE, color: "white", marginLeft: "10px", textTransform: "capitalize" }}
                                                        size="small"
                                                        onClick={() => {
                                                            props.onClickVideoPlay(value2);
                                                        }}
                                                    >
                                                        Play
                                                    </Button> :
                                                    <a
                                                        onClick={() => props.onClickAnyFile(value2)}
                                                        target="blank"
                                                        href={"https://d3i9v5bkcvmkzb.cloudfront.net/" + value2.filename}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{ background: DARK_BLUE, color: "white", marginLeft: "10px", textTransform: "capitalize" }}
                                                            size="small"
                                                        >
                                                            Preview
                                                        </Button>
                                                    </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

MaterialBoxes.propTypes = {};

export default MaterialBoxes;
