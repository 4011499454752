import axios from "axios";
import { toast } from "react-toastify";
import { getParams, getToken, getUserID } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";

const urlParams = new URLSearchParams(window.location.search);

const isIframe = urlParams.get("isIframe");


export const loginAction = (PhoneNo, Pass) => async (dispatch, getState) => {
    try {

        const body = [
            {
                "type": "Patient",
                "phoneNo": PhoneNo,
                "password": Pass,
                "deviceUDID": "",
                "deviceTypeId": "3",
                "verifyCode": "",
                "clinicCode": "",
                "currentAppVersion": "",
                "sendCaregiverInfo": "1",
                "FromPatientPortal": 1
            }
        ];


        const res = await axios.post(`${getBaseURL()}/Login`, body);


        if (!res.data[0]?.userId) return "Invalid credentials";

        dispatch(ReducerActions.loginSuccess(res.data[0]));

    } catch (error) {
        console.log(error);
        if (error.response.data.error?.code == 423) return error.response.data.error?.message.replace(":", "");
        return "Something went wrong!";

    }
};


export const UpdateProfile = (body, ProfilePicture) => async (dispatch, getState) => {
    try {


        let profileImgUploaded = null;

        if (typeof ProfilePicture !== "string") {

            profileImgUploaded = await dispatch(UpdateProfilePicture(ProfilePicture));

        }

        let body2 = body;
        if (profileImgUploaded) body2.photo = profileImgUploaded;


        const res = await axios.post(`${getBaseURL()}/UpdateProfileFromPortal/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`, body2);


        let data = { ...getState().User.UserData };


        data = { ...data, ...body };
        data.isActive = "1";

        if (profileImgUploaded) data.photo = profileImgUploaded;

        dispatch(ReducerActions.loginSuccess(data));

    } catch (error) {
        console.log("error", error);
        toast.error("Something went wrong!");
    }
};


export const UpdateProfilePicture = (Img) => async (dispatch, getState) => {
    try {


        const body = new FormData();

        body.append("file", Img);

        const res = await axios.post(`${getBaseURL()}/uploadPatientProfilePicture/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}`, body);

        return res.data.photo;

    } catch (error) {
        console.log("error", error);
        // toast.error("Something went wrong!")
        return null;
    }
};



export const GetPatientsProvidersList = (shareWithType = "patient") => async (dispatch, getState) => {
    try {



        const res = await axios.get(`${getBaseURL()}/GetListCareteamMemebersForPatientPortal/${getUserID(getState)}/${isIframe ? "provider" : "patient"}/${shareWithType}${getParams(getState)}`);

        if (typeof res.data === "string") return dispatch(ReducerActions.SetProviders([]));
        dispatch(ReducerActions.SetProviders(res.data || []));

        return res.data;

    } catch (error) {
        console.log("error", error);
        // toast.error("Something went wrong!")
        return null;
    }
};



export const GetResetPasswordVerificationCode = (PhoneNo) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${getBaseURL()}/forgotPasswordReq?phoneNo=${PhoneNo}`);
        if (res.data != 1) return "No account found.";
        return false;
    } catch (error) {
        console.log(error);
        return "Something went wrong!";

    }
};



export const ResetPasswordWithPhoneNoAndVerificationCode = (PhoneNo, Code, NewPassword) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${getBaseURL()}/ResetPasswordForPatient`, [
            {
                "type": "UserProfile",
                "phoneNo": PhoneNo,
                "token": Code,
                "pwd": NewPassword
            }
        ]);

        if (res.data.status != 1) return res.data.msg;
        return;

    } catch (error) {
        console.log(error);
        return "Something went wrong!";

    }
};
