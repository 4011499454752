import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import SurveyAndMaterialScreen from "../components/surveyAndMaterial/SurveyAndMaterialScreen";

const SurveyAndMaterial = props => {

    const dispatch = useDispatch();


    const [IsLoadingMaterial, setIsLoadingMaterial] = useState(true);
    const [IsLoadingSurvey, setIsLoadingSurvey] = useState(true);
    const [ErrorMsg, setErrorMsg] = useState("");


    const SurveyAndMaterialData = useSelector(state => state.SurveyAndMaterial.SurveyAndMaterialData);
    const SurveyList = useSelector(state => state.SurveyAndMaterial.SurveyList);


    useEffect(() => {
        getMaterial();
        getSurvey();
    }, []);


    async function getSurvey() {
        try {

            setIsLoadingSurvey(true);

            const res = await dispatch(Actions.GetAllSurveys());
            // if (res) setErrorMsg(res)

            setIsLoadingSurvey(false);

        } catch (error) {
            console.log(error);
            // setErrorMsg("Something went wrong")
        }
    }

    async function getMaterial() {
        try {

            setIsLoadingMaterial(true);

            const res = await dispatch(Actions.GetAllSurveyAndMaterial());

            // if (res) setErrorMsg(res)

            setIsLoadingMaterial(false);

        } catch (error) {
            console.log(error);
            // setErrorMsg("Something went wrong")
        }
    }

    return (
        <SurveyAndMaterialScreen
            fromPatientSide={true}
            IsLoadingMaterial={IsLoadingMaterial}
            SurveyAndMaterialData={SurveyAndMaterialData}
            ErrorMsg={ErrorMsg}
            SurveyList={SurveyList}
            IsLoadingSurvey={IsLoadingSurvey}
            getSurvey={() => getSurvey()}

        />
    );
};

SurveyAndMaterial.propTypes = {

};

export default SurveyAndMaterial;
