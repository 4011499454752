import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "User",
    initialState: {
        UserData: sessionStorage.getItem("@UserData") ? JSON.parse(sessionStorage.getItem("@UserData")) : null,
        IsAuth: sessionStorage.getItem("@UserData") ? true : false,
        Token: null,
        Providers: [],
        ClinicInfo: sessionStorage.getItem("@ClinicInfo") ? JSON.parse(sessionStorage.getItem("@ClinicInfo")) : null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.UserData = action.payload;
        },
        loginSuccess: (state, action) => {
            state.IsAuth = true;
            state.UserData = action.payload;
            state.ClinicInfo = action.payload.ClinicGroupItem;
            sessionStorage.setItem("@UserData", JSON.stringify(action.payload));
            sessionStorage.setItem("@ClinicInfo", JSON.stringify(action.payload.ClinicGroupItem));
        },
        setClinicInfo: (state, action) => {
            state.ClinicInfo = action.payload;
        },
        LogoutSuccess: (state, action) => {
            state.IsAuth = false;
            state.UserData = null;
            state.ClinicInfo = null;
            sessionStorage.removeItem("@UserData");
            sessionStorage.removeItem("@ClinicInfo");

            if(action.payload){
                window.location.reload();
            }
        },
        SetProviders: (state, action) => {
            state.Providers = action.payload;
        }
    },
});

export const { loginSuccess, LogoutSuccess, SetProviders, setUserData, setClinicInfo } = UserSlice.actions;


export const UserReducer = UserSlice.reducer;