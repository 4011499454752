import React from "react";
// import PropTypes from "prop-types";
import CurrentProgramMainScreen from "../components/currentProgram/CurrentProgramMainScreen";



const MyCurrentProgram = props => {
    return (
        <>
            <CurrentProgramMainScreen />
        </>
    );
};

MyCurrentProgram.propTypes = {

};

export default MyCurrentProgram;
