import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "lightbox-react/style.css";
import "./styles/styles.scss";

// import * as serviceWorker from "./serviceWorker";

import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

// import makeStyles from "@mui/styles/makeStyles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
const theme = createTheme();

// const useStyles = makeStyles((theme) => {
//     {
//     // some CSS that access to theme
//     }
// });


const container = document.getElementById("root"); 

const root = ReactDOM.createRoot(container);
root.render(
    // <React.StrictMode >
    <BrowserRouter>
        <Provider store={store}>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
            />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);
